import moment from 'moment';
import toast from 'react-hot-toast';
import {
  AdditionalLoanDataInterface,
  LoanData,
  MetaInterface,
  SingleBreakdown,
} from './interfaces';
import { useTranslation } from '@lendsqr/lingua-react';
import { uniq } from 'lodash';

const qs = new URLSearchParams(window.location.search);
// const languageCode = qs.get('language_code');
const locale = qs.get('locale') ?? navigator.language;
const currency = qs.get('currency') ?? 'NGN';

export const money = (amount: number | string, setCurrency: string) => {
  try {
    // Extract numeric part from string if input is in format "$1000"
    const numericAmount =
      typeof amount === 'string'
        ? parseFloat(amount.replace(/[^\d.-]/g, ''))
        : amount;

    const number = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: setCurrency,
      minimumFractionDigits: 0, // Set to 0 to hide decimal places
    }).format(numericAmount);

    if (number.includes('NaN')) return '';
    else {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: setCurrency,
        minimumFractionDigits: 0, // Set to 0 to hide decimal places
      }).format(numericAmount);
    }
  } catch (e) {
    const numericAmount =
      typeof amount === 'string'
        ? parseFloat(amount.replace(/[^\d.-]/g, ''))
        : amount;

    const number = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 0, // Set to 0 to hide decimal places
    }).format(numericAmount);

    if (number.includes('NaN')) return '';
    else {
      return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        minimumFractionDigits: 0, // Set to 0 to hide decimal places
      }).format(numericAmount);
    }
  }
};

export const formatNumber = (number: number | string = 0) => {
  const numericAmount =
    typeof number === 'string'
      ? parseFloat(number.replace(/[^\d.-]/g, ''))
      : Number(number);

  if (isNaN(numericAmount)) {
    return ''; // Handle invalid input
  }

  return new Intl.NumberFormat('en-US').format(numericAmount);
};

export const formatAmount = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const periodToFrequency = (period = '') => {
  switch (period) {
    case 'day':
    case 'days':
      return 'daily';

    case 'week':
    case 'weeks':
      return 'weekly';

    case 'month':
    case 'months':
      return 'monthly';

    case 'year':
    case 'years':
      return 'yearly';
    default:
      return period;
  }
};

export const getValidTenorPeriodsForProduct = (product: any) => {
  const options = uniq([product?.min_tenor_period, product?.max_tenor_period]);
  const result =
    options?.filter(Boolean).length > 0
      ? options
      : ['days', 'weeks', 'months', 'years'];

  return result;
};

export const frequencyData = {
  days: '% per day',
  dayly: '% per day',
  weekly: '% per week',
  weeks: '% per week',
  week: '% per week',
  monthly: '% per month',
  months: '% per month',
  month: '% per month',
  yearly: '% per year',
  years: '% per year',
  year: '% per year',
  flat: '% flat',
};

export type frequencyType = {
  days: string;
  dayly: string;
  weekly: string;
  weeks: string;
  week: string;
  monthly: string;
  months: string;
  month: string;
  yearly: string;
  years: string;
  year: string;
  flat: string;
};

export const repaymentDates = (date: string | number | Date) => {
  try {
    let d = date;
    if (typeof date === 'string') {
      d = new Date(date);
    }
    return moment(d).format('MMM DD, yyyy');
  } catch (e) {
    console.warn(`[repaymentDate] date-> ${date}`);
    console.warn(e);
    return date?.toString();
  }
};

export const checkNumber = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode;

  if ((charCode >= 48 && charCode <= 57) || charCode === 8) {
    return true;
  }

  event.stopPropagation();
  event.preventDefault();
  return false;
};

export const base64ToFile = (
  url: string,
  filename: string,
  mimeType: string
) => {
  const { translate } = useTranslation();

  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    })
    .catch((e) => {
      console.log(e);
      toast.error(translate('invitation-web-app-file-upload-error'));
    });
};

export const getFeeAmount = (
  category: String,
  fees: Array<any>,
  principal: number,
  interest: number
) => {
  if (fees?.length == 0) {
    return 0;
  }

  const categoryFee = fees.filter((fee) => fee.category === category);

  return feeCalculator(categoryFee, principal, interest);

  // return fees?.reduce((num, fee) => {
  //   if(fee?.category === category){
  //     return fee?.amount_due + num;
  //   }

  // }, 0);
};

export const feeCalculator = (
  fee: any,
  principal: number,
  interest: number
) => {
  if (!fee) {
    return 0;
  }

  return (
    fee?.reduce?.((sum: number, fee: any) => {
      let amount = 0;
      const fee_type = fee.type?.trim()?.toLowerCase() || 'flat';
      switch (fee_type) {
        case 'percentage':
          amount = percentageFee(fee, principal, interest);
          break;
        case 'hybrid':
          amount += hybridFee(fee, principal, interest);
          break;
        default:
          amount = fee.amount || 0;
          break;
      }
      return sum + amount;
    }, 0) ?? 0
  );
};

export const hybridFee = (fee: any, principal: number, interest: number) => {
  let temp_fee = 0;

  if (fee.applicable_to && fee.percentage) {
    if (fee.applicable_to.toLowerCase() === 'principal') {
      // if min amount is set, calculate a percnetage of the principal
      temp_fee = (fee.percentage / 100) * principal;
    } else {
      // if min amount is set, calculate a percnetage of the principal and interest
      temp_fee = (fee.percentage / 100) * principal + interest;
    }
    // eg 1% of principal + 450
    if (fee.amount) {
      temp_fee += fee.amount;
    }
  } else if (fee.fixed) {
    temp_fee = fee.amount;
  }

  if (fee.min_amount && temp_fee < fee.min_amount) {
    temp_fee = fee.min_amount;
  } else if (fee.max_amount && temp_fee > fee.max_amount) {
    temp_fee = fee.max_amount;
  }
  return temp_fee;
};

export const percentageFee = (
  fee: any,
  principal: number,
  interest: number
) => {
  let temp_fee = 0;
  if (fee.applicable_to) {
    if (fee.applicable_to.toLowerCase() === 'principal') {
      //  calculate a percnetage of the principal
      temp_fee = (fee.percentage / 100) * principal;
    } else {
      //  calculate a percnetage of the principal and interest
      temp_fee = (fee.percentage / 100) * principal + interest;
    }
  }

  if (fee.min_amount && temp_fee < fee.min_amount) {
    temp_fee = fee.min_amount;
  } else if (fee.max_amount && temp_fee > fee.max_amount) {
    temp_fee = fee.max_amount;
  }
  return temp_fee;
};

export const getLoanAmountFromSchedule = (schedule: Array<SingleBreakdown>) => {
  if (schedule?.length === 0) {
    return 0;
  }
  console.log(schedule);

  return schedule?.reduce((num, payment) => {
    return payment?.amount_due + num;
  }, 0);
};

export const fullDateTimeFormat = (date: string | number) =>
  new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
  });

export function validateLocaleAndCurrency() {
  try {
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(1000);

    return { locale, currency };
  } catch (error) {
    return { locale: 'en-NG', currency: 'NGN' };
  }
}

export function validateLocale() {
  try {
    // Attempt to create a NumberFormat object with the provided locale
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'USD', // You can use any valid currency code here
    }).format(1000); // You can use any sample number for testing

    return locale; // If no error is thrown, the locale is valid
  } catch (error) {
    console.error('Error:', error); // Log the error for debugging
    return 'en-NG'; // Default to 'en-NG' for an invalid locale
  }
}

export function validateCurrency() {
  try {
    // Attempt to create a NumberFormat object with the provided currency
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(1000); // You can use any sample number for testing

    return currency; // If no error is thrown, the currency is valid
  } catch (error) {
    console.error('Error:', error); // Log the error for debugging
    return 'NGN'; // Default to 'NGN' for an invalid currency
  }
}

export const showCLF = (additional_loan_data: AdditionalLoanDataInterface) => {
  const showConfigurableForm =
    additional_loan_data?.meta?.status.toLowerCase() === 'active' || false;

  return showConfigurableForm;
};

export const isOnlyCLF = (data: LoanData) => {
  const additionalLoanData =
    data?.product?.attributes?.additional_loan_data?.meta;

  // Check if additionalLoanData is not empty using isObjectNotEmpty
  return isObjectNotEmpty(additionalLoanData as MetaInterface);
};
export const isObjectNotEmpty = <T extends object>(obj: T): boolean => {
  // do not go further if obj is null
  if (!obj) {
    return false;
  }
  return Object.keys(obj).length > 0;
};

export const fileMimeTypes = [
  'mp3',
  'wav',
  'ogg',
  'aac',
  'mp4',
  'webm',
  'ogg',
  'pdf',
];

export const yesterday = moment().subtract(1, 'day');
export const valid = (current: moment.Moment): boolean => {
  return current.isAfter(yesterday);
};

export const dataObject = {
  address: '15 Alhaji Bankole Crescent, Ikeja, Lagos',
  current_employer: '',
  educational_attainment: '',
  employment_category: '',
  employment_status: '',
  gender: 'Female',
  latitude: 0,
  longitude: 0,
  marital_status: 'Single',
  monthly_net_income: '',
  no_of_dependent: '3',
  product_id: '0',
  proposed_payday: '',
  proposed_tenor: 0,
  proposed_tenor_period: '',
  purpose: '',
  requested_amount: 0,
  sector_of_employment: '',
  type_of_residence: '',
  work_email: '',
  work_start_date: '',
};

export const getValidMandates = (
  allMandates: any,
  proposed_payday: string,
  requested_amount: number
) => {
  const validMandates = allMandates.filter((mandate: any) => {
    if (mandate.end_date == null) {
      return false;
    }

    const loanDate = new Date(proposed_payday);

    loanDate?.setMonth(loanDate?.getMonth() + 3);

    const mandateDate = new Date(
      mandate.end_date.slice(0, 10).replace(/-/g, '/')
    );

    return (
      mandate.amount >= requested_amount &&
      (!mandateDate ||
        (loanDate?.getTime() &&
          Number(mandateDate.getTime()) >= Number(loanDate?.getTime())))
    );
  });

  return validMandates;
};

export const dateFormatter = (dt: Date) => {
  let date = dt;

  if (typeof dt == 'string') {
    date = new Date(dt);
  }

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formattedDay = +day < 10 ? `0${day}` : day;
  const formattedDate = `${formattedDay}-${monthNames[monthIndex]}-${year}`;
  return monthNames[monthIndex] ? formattedDate : 'Invalid Date';
};

export const formatDateValidation = (validationString: any, type: string) => {
  if (!validationString) return '';
  if (type === 'fixed')
    return moment(validationString).format('YYYY/MM/DD HH:mm:ss');
  const [value, unit, action] = validationString.split(' ');

  if (action === 'after') {
    return moment().add(value, unit).format('YYYY/MM/DD HH:mm:ss');
  }
  if (action === 'before') {
    return moment().subtract(value, unit).format('YYYY/MM/DD HH:mm:ss');
  }
  return moment().format('YYYY/MM/DD HH:mm:ss');
};
