type CheckboxFieldProp = {
  id: string;
  name: string;
  value: string;
  label: string;
  handleChange: () => void;
  isChecked: boolean;
};

function CheckboxField({
  id,
  name,
  value,
  label,
  isChecked,
  handleChange,
}: Readonly<CheckboxFieldProp>) {
  return (
    <div className="row-center">
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        className="mr-8 checkbox"
        checked={isChecked}
        onChange={handleChange}
      />
      <label className="sm-regular" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export { CheckboxField };
