import { useState, useEffect, useContext } from 'react';
import { Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Section from './Section';

import {
  PageInterface,
  SectionInterface,
  FormDataInterface,
  AdditionalLoanDataInterface,
} from '../../../utils/interfaces';
import useApplicationStore from '../../../state';
import { ScrollContext } from '../../../pages';

import { FormLayout } from '../layout/FormLayout';
import { setFieldValidation } from './pagesValidation';
import { ScreenState } from '../bnpl';

interface CLFCurrentPageProps {
  loanProduct: any;
  page: PageInterface;
  initialValues: any;
  setInitialValues: React.Dispatch<React.SetStateAction<any>>;
  dynamicInitials: (loanProduct: AdditionalLoanDataInterface) => any;
}

const CLFCurrentPage = ({
  loanProduct,
  page,
  dynamicInitials,
  initialValues,
  setInitialValues,
}: CLFCurrentPageProps) => {
  const scrollRefObject = useContext(ScrollContext);
  const [validation, setValidation] = useState<any>(null);

  const [
    setLoan,
    scrollToTop,
    additional_loan_data,
    clf_page,
    setCLFPage,
    attributes,
    setCLFAttribute,

    preSubmitForm,
    loading,
    next,
    back,
    loanType,
  ] = useApplicationStore((state) => [
    state.setLoan,
    state.scrollToTop,
    state.additional_loan_data,
    state.clf_page,
    state.setCLFPage,
    state.attributes,
    state.setCLFAttribute,

    state.preSubmitForm,
    state.loading,
    state.next,
    state.back,
    state.loanType,
  ]);

  const {
    register,
    formState: { errors },
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm<FormDataInterface>({
    defaultValues: {
      ...JSON.parse(attributes)[additional_loan_data?.meta.name as string],
    },
  });

  const onSubmit = async (data: FormDataInterface) => {
    try {
      const dataKey = additional_loan_data?.meta?.name;

      if (clf_page < loanProduct?.pages.length - 1) {
        const additionalData = JSON.stringify({ ...data });
        setCLFAttribute(additionalData);

        setCLFPage(clf_page + 1);
      } else {
        const additionalData = JSON.stringify({
          [dataKey as string]: { ...data },
        });

        setLoan({ attributes: additionalData });
        setCLFAttribute(additionalData);

        if (loanType === 'pay_later') {
          await preSubmitForm(ScreenState.CONFIGURABLE_FORM);
        } else {
          next(ScreenState.CONFIGURABLE_FORM, ScreenState.PAYMENT);
        }
      }

      scrollToTop(scrollRefObject);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loanProduct) {
      setInitialValues(dynamicInitials(loanProduct));
    }
  }, [clf_page]);

  const handlePreviousStep = () => {
    if (clf_page <= 0) {
      // if (loan_data?.product?.attributes.show_only_clf) {
      //   setScreen(ScreenState.ORDER_INFORMATION);
      // } else {
      //   setScreen(ScreenState.EDUCATIONAL_INFORMATION);
      // }
      back();
    } else {
      setCLFPage(clf_page - 1);
    }
    scrollToTop(scrollRefObject);
  };

  const getFields = (): Array<any> => {
    let fields: any = [];

    page?.sections.forEach((section) => {
      fields = [...fields, ...section.fields];
    });

    return fields;
  };

  useEffect(() => {
    const setForm = async () => {
      const fields = getFields();

      const validation = await setFieldValidation(fields);

      setValidation(validation);
    };

    setForm();
  }, [page]);

  return (
    <>
      {validation && (
        <FormLayout
          onBack={handlePreviousStep}
          onNext={() => {}}
          title=""
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validation}
          loading={loading}
        >
          {page?.sections?.map((section: SectionInterface, idx: number) => (
            <div key={section.name}>
              <Text fontSize="lg" fontWeight="semibold">
                {section.name}
              </Text>
              <Text fontSize="xs">{section.description}</Text>

              <Section
                key={idx}
                section={section}
                setValue={setValue}
                getValue={getValues}
                setError={setError}
                clearErrors={clearErrors}
                register={register}
                control={control}
                errors={errors}
              />
            </div>
          ))}
        </FormLayout>
      )}
    </>
  );
};

export default CLFCurrentPage;
