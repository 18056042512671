import { useField } from 'formik';
import { TextFieldProp } from '../bnpl/bnpl';
import { Spacer } from './Spacer';
import { checkNumber } from '../../utils/formatter';

const Textfield = ({
  type = 'text',
  label,
  name,
  disabled,
  hint,
}: TextFieldProp) => {
  const [field, meta] = useField(name);

  return (
    <div className="input-holder-holder">
      <div className="input-holder w-300px">
        <input
          disabled={disabled}
          type="text"
          // type={type}
          className="form-control radius-4"
          placeholder={label}
          {...field}
          id={field.name}
          onKeyDown={type === 'number' ? checkNumber : undefined}
          // inputMode="numeric" pattern="[-+]?[0-9]*[.,]?[0-9]+"
        />

        <label
          htmlFor={field.name}
          className={`input-label ${disabled && !field.value && 'transperent'}`}
        >
          {label}
        </label>
      </div>

      {hint && (
        <>
          <Spacer height={4} />
          <p className="xs-regular">{hint}</p>
        </>
      )}

      {meta.error && meta.touched && <p className="error">{meta.error}</p>}
    </div>
  );
};

export { Textfield };
