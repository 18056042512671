import { Spacer } from './Spacer';
import { Textfield } from './Textfield';

type BVNProp = {
  label: string;
  name: string;
};

function BVNField({ label, name }: Readonly<BVNProp>) {
  return (
    <>
      <Textfield type="number" label={label} name={name} />
      <Spacer height={4} />
      <p className="xs-bold textfield-info pointer">Don’t remember your BVN?</p>
    </>
  );
}

export { BVNField };
