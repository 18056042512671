import { createContext } from 'react';
import { SupportedLocales } from './localeConfig';

interface LanguageSwitchConfg {
  currentLocale: string;
  setCurrentLocale: (locale: string) => void;
}

export const LanguageSwitchContext = createContext<LanguageSwitchConfg>({
  currentLocale: SupportedLocales.enUS,
  setCurrentLocale: (currentLocale: string) => {
    return currentLocale;
  },
});

export function parseTypes(options: any) {
  const colors: any[] = [];

  for (const option of options) {
    switch (option.data_type) {
      case 'color':
        colors.push(option);
        break;
      // case 'text':
      //   dictionary[option.property] = option.value || option.default_value;
      //   break;
      // case 'config':
      //   configs[option.property] = option.value || option.default_value;
      //   break;
      // case 'asset':
      //   assets[option.property] = option.value || option.default_value;
      //   break;
      // case 'link':
      //   links[option.property] = option.value || option.default_value;
      //   break;
      default:
    }
  }

  parseStyleConfigs(colors);

  // return { colors, dictionary, assets, configs, links };
}

function parseStyleConfigs(configs: any) {
  const styleEl = document.createElement('style');

  // Append <style> element to <head>
  document.head.appendChild(styleEl);

  // Grab style element's sheet
  const styleSheet = styleEl.sheet;

  if (!styleSheet) {
    return;
  }

  for (const config of configs) {
    // Insert CSS Rule
    styleSheet.insertRule(
      `:root {--${config.property}:${config.value || config.default_value} }`,
      styleSheet.cssRules.length
    );
  }
}

// const [currentLocale, setCurrentLocreateContext]
