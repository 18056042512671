import { useMemo, useCallback, useState, FC, useEffect } from 'react';
import {
  Text,
  HStack,
  Image,
  FormLabel,
  Button,
  Box,
  Spinner,
} from '@chakra-ui/react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import {
  AddPhoto,
  FieldInterface,
  UploadedDocumentsInterface,
} from '../../../utils/interfaces';
import { apiRequest } from '../../../utils/api-request';
import { postUploadPhoto, postUploadFile } from '../../../utils/endpoints';
import shallow from 'zustand/shallow';
import useApplicationStore from '../../../state';
import Required from '../../../assets/required.png';
import { useTranslation } from '@lendsqr/lingua-react';
import { fileMimeTypes } from '../../../utils/formatter';

interface FileUploadProps {
  field: FieldInterface;
  setValue: any;
  getValue: any;
}

const FileUpload: FC<FileUploadProps> = ({ field, setValue }) => {
  const { translate } = useTranslation();
  const [locale, uploadedDocuments, setUploadedDocuments] = useApplicationStore(
    (state) => [
      state.locale,
      state.uploadedDocuments,
      state.setUploadedDocuments,
    ],
    shallow
  );

  const [acceptedFileTypes, setAcceptedFileTypes] = useState<Accept>({});
  const [uploadButton, setUploadButton] = useState('');
  const [document, setDocument] = useState<
    UploadedDocumentsInterface | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const allowedFileTypes = useMemo(() => {
    const allowedTypes =
      field.validation.allowed ?? getFileTypeByField(field.type);
    return allowedTypes.split(',').map((type) => type.trim());
  }, [field.validation.allowed, field.type]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      try {
        setLoading(true);
        setDocument(undefined);

        const fileBlob = acceptedFiles[0];

        const blobFile =
          fileBlob instanceof Blob ? fileBlob : new Blob([fileBlob]);

        if (blobFile.size && blobFile.size / 1000000 > 5) {
          const errorMessage = await translate(
            'invitation-web-app-file-size-error'
          );
          setLoading(false);
          await toast.error(errorMessage);
          return;
        }

        if (rejectedFiles.length) {
          toast.error(
            `File Format Not supported, upload ${allowedFileTypes.join(', ')}`
          );
          setLoading(false);
          return;
        }

        const xLocale = {
          XLocale: locale,
        };

        const formData = new FormData();

        if (blobFile.type?.includes('image')) {
          formData.append('file', blobFile, 'user-document.png');
        } else {
          formData.append('file', blobFile);
          for (const type of fileMimeTypes) {
            formData.append('mimetypes[]', type);
          }
        }

        const uploadRes = async () => {
          if (blobFile.type.includes('image')) {
            const contentType = {
              ContentType: 'multipart/form-data',
            };

            const res = await apiRequest(
              postUploadPhoto('irorun'),
              'post',
              { ...contentType, ...xLocale },
              formData
            );

            return res;
          } else {
            const contentType = {
              ContentType: 'multipart/form-data',
            };

            const res = await apiRequest(
              postUploadFile('irorun'),
              'post',
              { ...contentType, ...xLocale },
              formData
            );

            return res;
          }
        };
        const { data } = (await uploadRes()) as AddPhoto;
        setValue(field.id, data.url);

        const document = {
          data: {
            name: blobFile.name,
            type: blobFile.type,
            url: data.url,
          },
          id: field.id,
          status: 'success',
        };
        setDocument(document);
        setUploadedDocuments(document);
        setLoading(false);
      } catch (error: { message: string } | any) {
        setLoading(false);
        toast.error(error.message);
      }
    },
    [allowedFileTypes]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    noClick: true,
  });

  useEffect(() => {
    if (uploadedDocuments) {
      setDocument(
        uploadedDocuments.find((document) => document.id === field.id)
      );
    }

    if (field.type === 'upload') {
      setUploadButton('Upload File');
    } else if (field.type === 'video') {
      setUploadButton('Upload Video');
    } else if (field.type === 'audio') {
      setUploadButton('Upload Audio');
    } else if (field.type === 'image') {
      setUploadButton('Upload Picture');
    }

    const updatedAcceptedFileTypes: Accept = {};

    switch (field.type) {
      case 'upload':
        updatedAcceptedFileTypes['image/png'] = ['.png'];
        updatedAcceptedFileTypes['image/jpeg'] = ['.jpeg', '.jpg'];
        updatedAcceptedFileTypes['application/pdf'] = ['.pdf'];
        break;
      case 'audio':
        updatedAcceptedFileTypes['audio/mpeg'] = ['.mp3'];
        break;
      case 'video':
        updatedAcceptedFileTypes['video/mp4'] = ['.mp4'];
        break;
      default:
        break;
    }

    setAcceptedFileTypes(updatedAcceptedFileTypes);
  }, []);

  return (
    <>
      <HStack gap={'0.2rem'} alignItems={'flex-start'}>
        <FormLabel
          lineHeight="1"
          mb="4px"
          mx="0"
          fontSize="0.885rem"
          sx={{
            '@media screen and (min-width: 768px) and (orientation: portrait)':
              {
                fontWeight: 'bold',
              },
            '@media screen and (min-width: 1024px)': {
              fontWeight: 'bold',
            },
          }}
        >
          {field.label}
        </FormLabel>
        {field.validation.required ? (
          <Image src={Required} width={'7px'} height={'7px'} />
        ) : (
          ''
        )}
      </HStack>
      {!document && (
        <HStack alignItems={'center'}>
          {loading && <Spinner size="lg" mr="15px" />}
          <Button
            type="button"
            size="lg"
            variant="green"
            width={{ base: '120px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="0.5rem"
            mb="1rem"
            bg="#24C6A1"
            isDisabled={loading}
            {...getRootProps()}
            onClick={open}
          >
            <input type="file" {...getInputProps()} />
            {uploadButton}
          </Button>
        </HStack>
      )}

      {document && (
        <HStack
          w="fit-content"
          my="1rem"
          // mx="auto"
          rounded="4px"
          p="1rem"
          justifyContent={'flex-start'}
        >
          <Box minWidth={'4rem'} maxW={'7rem'} height={'fit-content'} mr="15px">
            {document.data.type.includes('image') ? (
              <Image
                rounded="5px"
                objectFit="contain"
                w="full"
                h="4rem"
                borderRadius={'50%'}
                src={document.data.url}
              />
            ) : (
              <Text
                fontSize="0.75rem"
                textAlign="center"
                width="85%"
                color="brand.blue_main"
              >
                {document.data.name}
              </Text>
            )}
          </Box>
          <Button isDisabled={loading} {...getRootProps()} onClick={open}>
            Upload new file
            <input type="file" {...getInputProps()} />
          </Button>
        </HStack>
      )}
    </>
  );
};

export default FileUpload;

function getFileTypeByField(fieldType: string): string {
  switch (fieldType) {
    case 'audio':
      return 'audio/mpeg';
    case 'video':
      return 'video/mp4';
    case 'image':
      return 'image/jpeg, image/png';
    default:
      return '';
  }
}
