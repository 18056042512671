import bnpl_x from '../../../public/bnpl-x.svg';

type HeaderType = {
  title: string;
  onClose: () => void;
};

function Header({ title, onClose }: Readonly<HeaderType>) {
  return (
    <div className="bnlp-header helper-padding">
      <p className="heading-20">{title}</p>

      <img src={bnpl_x} alt="close icon" onClick={onClose} />
    </div>
  );
}

export { Header };
