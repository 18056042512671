import { useState } from 'react';

type ReturnType = {
  validate: (value: string) => void;
  values: {
    length: boolean;
    upperCase: boolean;
    lowerCase: boolean;
    char: boolean;
  };
  validated: boolean;
};

const usePasswordValidation = (): ReturnType => {
  const [length, setLengthError] = useState(false);
  const [upperCase, setUpperCaseError] = useState(false);
  const [lowerCase, setLowerCaseError] = useState(false);
  const [char, setCharError] = useState(false);

  const validate = (value: string) => {
    if (value.length > 7) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }
    if (/[A-Z]/.test(value)) {
      setUpperCaseError(true);
    } else {
      setUpperCaseError(false);
    }
    if (/[a-z]/.test(value)) {
      setLowerCaseError(true);
    } else {
      setLowerCaseError(false);
    }
    if (/[=!@#&$%]/.test(value)) {
      setCharError(true);
    } else {
      setCharError(false);
    }
  };

  const values = { length, upperCase, lowerCase, char };

  const validated = Object.values(values).every((item) => item);

  return { validate, values, validated };
};

export default usePasswordValidation;
