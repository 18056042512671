import useApplicationStore from '../../../../state';
import { Spacer } from '../../../base/Spacer';
import { Controltypes } from '../../bnpl';
import { Footer } from '../../Footer';
import { BNPLBody } from '../../layout/BNPLBody';
import { AddItem } from '../../../base/AddItem';
import { SingleMandate } from './SingleMandate';
import { useEffect, useMemo } from 'react';
import { getValidMandates } from '../../../../utils/formatter';

function SelectMandate({ addClick, onNext, onBack }: Controltypes) {
  const [mandates, bnpl_values, selectedMandate, setSelectedMandate] =
    useApplicationStore((state) => [
      state.mandates,
      state.bnpl_values,
      state.selectedMandate,
      state.setSelectedMandate,
    ]);

  const handleSelectClick = (mandate: any) => {
    setSelectedMandate(mandate);
  };

  const mandateList = useMemo(() => {
    return getValidMandates(
      mandates,
      bnpl_values?.proposed_payday,
      bnpl_values?.requested_amount
    );
  }, [mandates, bnpl_values]);

  useEffect(() => {
    if (!selectedMandate) {
      setSelectedMandate(mandateList[0]);
    }
  }, []);

  return (
    <>
      <BNPLBody title="Select a mandate to use for your repayments.">
        <Spacer height={20} />

        <div>
          {mandateList &&
            mandateList.map((mandate: any) => {
              return (
                <SingleMandate
                  key={mandate.id}
                  mandate={mandate}
                  setSelected={handleSelectClick}
                  isSelected={mandate.id === selectedMandate?.id}
                />
              );
            })}

          <Spacer height={20} />

          <AddItem onClick={addClick!} text="Add a new mandate" />
        </div>
      </BNPLBody>

      <Footer disabled={!selectedMandate} onBack={onBack} onNext={onNext} />
    </>
  );
}

export { SelectMandate };
