import { useNavigate, useParams } from 'react-router-dom';
import useApplicationStore from '../state';
import { useEffect } from 'react';

//Custom hook to check the status of the token
const useCheckToken = () => {
  const navigate = useNavigate();

  const [getLoanDetails, setToken, setLocation] = useApplicationStore(
    (state) => [state.getLoanDetails, state.setToken, state.setLocation]
  );

  const { token } = useParams();

  useEffect(() => {
    (async () => {
      if (token) {
        setToken(token);
        setLocation();
        await getLoanDetails(token);
      } else {
        navigate('/404');
      }
    })();
  }, [navigate, getLoanDetails, setToken, token]);
};

export { useCheckToken };
