import useApplicationStore from '../../../../state';
import { SingleCard } from './SingleCard';

function SelectCards() {
  const [cards, selectedCard, setSelectedCard] = useApplicationStore(
    (state) => [state.cards, state.selectedCard, state.setSelectedCard]
  );

  const setSelected = (card: any) => {
    setSelectedCard(card);
  };

  return (
    <div>
      {cards &&
        cards.map((card) => {
          return (
            <SingleCard
              key={card?.id}
              card={card}
              isSelected={card?.id === selectedCard?.id}
              setSelected={setSelected}
            />
          );
        })}
    </div>
  );
}

export { SelectCards };
