import { BNPLBody } from '../layout/BNPLBody';
import confirmationImage from '../../../assets/confirmation.svg';
import { useMemo } from 'react';
import { Spacer } from '../../base/Spacer';
import useApplicationStore from '../../../state';

function Confirmation() {
  const [loanType, bnpl_conf] = useApplicationStore((state) => [
    state.loanType,
    state.bnpl_conf,
  ]);

  const message = useMemo(() => {
    let tempMessage = bnpl_conf?.bnpl?.application_successful_message;

    if (!tempMessage && loanType === 'pay_later') {
      tempMessage =
        'Your loan application was successful. You can always log into your account to manage your loan information.';
    }

    if (!tempMessage && loanType === 'pay_now') {
      tempMessage =
        'Your transaction has been completed. You will receive a confirmation email shortly';
    }

    return tempMessage;
  }, [bnpl_conf]);

  return (
    <>
      <BNPLBody>
        <div className="confirm-container">
          <img src={confirmationImage} alt="confirmation " />

          <Spacer height={24} />

          <p className="sm-regular text-center">
            {/* {bnpl_conf?.bnpl?.application_successful_message} */}
            {message}
          </p>
        </div>
      </BNPLBody>

      {/* <Footer onBack={onBack} onNext={onNext} /> */}
    </>
  );
}

export { Confirmation };
