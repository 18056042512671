// import useApplicationStore from '../../../state';
import { CreateMandate } from './create-mandate/CreateMandate';

function AddBank() {
  // const [setScreen] = useApplicationStore((state) => [state.setBNPLScreen]);

  const onAction = () => {};

  return <CreateMandate onBack={onAction} onNext={onAction} type="bank" />;
}

export { AddBank };
