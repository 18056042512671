import { Spacer } from '../../../base/Spacer';
import mastercard from '../../../../assets/Mastercard.svg';
import visa from '../../../../assets/Visa.svg';

type SingleCardType = {
  card: any;
  isSelected: boolean;
  setSelected: (card: any) => void;
};

function SingleCard({ card, isSelected, setSelected }: SingleCardType) {
  const randId = Math.random();

  return (
    <label
      className={`box-transfer-item
            ${isSelected && 'selected-box-transfer-item'}`}
      htmlFor={`${randId}`}
    >
      {isSelected && <div className="item-background"></div>}

      <div className="full-width card-transfer-top flex bank-card-holder row-center">
        <div style={{ width: 'fit-content' }} className="card-transfer-radio ">
          <input
            id={`${randId}`}
            type="radio"
            onChange={() => setSelected(card)}
            checked={isSelected}
          />

          <label
            htmlFor={`${randId}`}
            className={`${
              !isSelected
                ? 'radio-not-selected-outter'
                : 'radio-selected-outter'
            }`}
          >
            <div
              className={`${
                !isSelected
                  ? 'radio-not-selected-inner'
                  : 'radio-selected-inner'
              }`}
            />
          </label>
        </div>

        <Spacer width={8} />

        <div className="card-image">
          <img
            src={card?.card_type?.includes('Mastercard') ? mastercard : visa}
          />
        </div>

        <Spacer width={8} />

        <p className="body-regular">
          {card?.card_type?.includes('Mastercard') ? 'Mastercard' : 'Visa'}{' '}
          Ending with {card?.card_last4}
        </p>
        <Spacer width={4} />

        <p className="sm-regular">
          (exp. {card?.card_exp_month}/{card?.card_exp_year})
        </p>
      </div>
    </label>
  );
}

export { SingleCard };
