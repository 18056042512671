import { Header } from './Header';
import { OfferSelection } from './offer-selection/OfferSelection';
import { ScreenState } from './bnpl';
import { OrderInformation } from './order-information/OrderInformation';
import { AccountInformation } from './account-information/AccountInformation';
import { useCallback, useMemo } from 'react';
import { PersonalInformation } from './personal-information/PersonalInformation';
import { BVNValidation } from './bvn-validation/BVNValidation';
import { SelfieVerification } from './selfie-verification/SelfieVerification';
import { ReviewTerms } from './review-terms/ReviewTerms';
import { Payments } from './payments/Payments';
import { Confirmation } from './confirmation/Confirmation';
import ConfigurableForms from './configurable-forms/ConfigurableForms';
import useApplicationStore from '../../state';
import { EducationalInformation } from './educational-information/EducationalInformation';
import { AddBank } from './payments/AddBanks';
import { Pin } from './pin/Pin';
import { BNPLFileUpload } from './file-upload/FileUpload';
import { PaymentMethods } from './payments/PaymentMethods';

function Content({ sideSteps }: Readonly<{ sideSteps: Array<any> }>) {
  const [additional_loan_data, screen] = useApplicationStore((state) => [
    state.additional_loan_data,
    state.bnpl_screen,
  ]);

  const renderScreen = useCallback(() => {
    switch (screen) {
      case ScreenState.OFFER_SELECTION:
        return <OfferSelection />;
      case ScreenState.ORDER_INFORMATION:
        return <OrderInformation />;
      case ScreenState.CONFIGURABLE_FORM:
        return <ConfigurableForms />;
      case ScreenState.ACCOUNT_INFORMATION:
        return <AccountInformation />;
      case ScreenState.PERSONAL_INFORMATION:
        return <PersonalInformation />;
      case ScreenState.EDUCATIONAL_INFORMATION:
        return <EducationalInformation />;
      case ScreenState.BVN_VALIDATION:
        return <BVNValidation />;
      case ScreenState.SELFIE_CHECK:
        return <SelfieVerification />;
      case ScreenState.REVIEW_TERMS:
        return <ReviewTerms />;
      case ScreenState.PAYMENT_METHODS:
        return <PaymentMethods />;
      case ScreenState.DOCUMENTS:
        return <BNPLFileUpload />;
      case ScreenState.ADD_BANK:
        return <AddBank />;
      case ScreenState.PAYMENT:
        return <Payments />;
      case ScreenState.PIN:
        return <Pin />;
      case ScreenState.CONFIRMATION:
        return <Confirmation />;
    }
  }, [screen]);

  const title = useMemo(() => {
    const currentScreen = sideSteps?.find((step) => step.screen === screen);

    return currentScreen?.screen === ScreenState.CONFIGURABLE_FORM
      ? additional_loan_data?.meta?.name ?? ''
      : currentScreen?.title;
  }, [screen]);

  return (
    <div className="helper-content ">
      <Header title={title ?? ''} onClose={() => {}} />

      {renderScreen()}
    </div>
  );
}

export { Content };
