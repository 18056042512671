import axios from 'axios';

const clientConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': import.meta.env.VITE_REACT_APP_X_API_KEY || '',
    // 'Request_id': lsqrefHeader
  },
};

const axiosInstance = axios.create(clientConfig);

export { axiosInstance };
