import { Spinner, VStack, Text } from '@chakra-ui/react';
import { ScrollContext } from './Home';
import { useTranslation } from '@lendsqr/lingua-react';
import { useRef } from 'react';
import useApplicationStore from '../state';
import { useCheckToken } from '../hooks/useCheckToken';
import { BNPLLayout } from '../components/BNPLLayout';

function BNPL() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const { translate } = useTranslation();

  const [status] = useApplicationStore((state) => [state.status]);

  useCheckToken();

  switch (status) {
    case 'loading':
      return (
        <VStack
          h={`100vh`}
          w="100vw"
          justify="center"
          align="center"
          position="fixed"
        >
          <Spinner size="xl" />
        </VStack>
      );

    case 'success':
      return (
        <ScrollContext.Provider value={scrollableContainerRef}>
          <BNPLLayout />
        </ScrollContext.Provider>
      );

    case 'failed':
      return (
        <VStack
          h={`100vh`}
          w="100vw"
          justify="center"
          align="center"
          position="fixed"
        >
          <Text>{translate('invitation-web-app-failed')}</Text>
        </VStack>
      );
    default:
      break;
  }
}

export { BNPL };
