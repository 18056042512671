type LayoutProps = {
  children: React.ReactNode;
  title?: string;
};

function BNPLBody({ children, title }: LayoutProps) {
  return (
    <div className="helper-padding bnpl-body">
      <div className="height-rest">
        <p className="sm-regular">{title}</p>
        {children}
      </div>
    </div>
  );
}

export { BNPLBody };
