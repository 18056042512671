import useApplicationStore from '../../../state';
import { Button } from '../../base/Button';
import { Spacer } from '../../base/Spacer';
import { ScreenState } from '../bnpl';
import { Footer } from '../Footer';
import { BNPLBody } from '../layout/BNPLBody';

type ChoosePaymentProp = {
  setMethod: (value: string) => void;
  loading?: boolean;
};

function ChoosePayment({ setMethod, loading }: ChoosePaymentProp) {
  const [setScreen] = useApplicationStore((state) => [state.setBNPLScreen]);

  const onBack = () => {
    setScreen(ScreenState.PAYMENT);
  };

  return (
    <>
      <BNPLBody title="Choose your payment method">
        <Spacer height={40} />

        <Button
          text="Pay with debit card"
          type="primary"
          extraClass="mr-8"
          onClick={() => setMethod('card')}
        />

        <Spacer height={20} />

        <Button
          text="Pay with bank account"
          type="primary"
          extraClass="mr-8"
          onClick={() => setMethod('bank')}
        />
      </BNPLBody>

      <Footer loading={loading} onBack={onBack} noPrimary />
    </>
  );
}

export { ChoosePayment };
