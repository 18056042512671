import { ContentInfo } from '../bnpl/bnpl';
import { InfoLayout } from '../InfoLayout';

type RowInfoDetailsProps = {
  content: Array<ContentInfo>;
};

function RowInfoDetails({ content }: Readonly<RowInfoDetailsProps>) {
  return (
    <InfoLayout>
      <div className="row-between flex-wrap">
        {content.map((info) => {
          return (
            <div key={info.title} className=" row-info-details-item ">
              <p className="sm-regular">{info.title}</p>
              <p className="sm-bold">{info.value}</p>
            </div>
          );
        })}
      </div>
    </InfoLayout>
  );
}

export { RowInfoDetails };
