import { useMemo } from 'react';
import rightArrow from '../../../assets/right-arrow.svg';
import useApplicationStore from '../../../state';

function PayAllNow() {
  const [setValues, loanType, bnpl_conf] = useApplicationStore((state) => [
    state.setValues,
    state.loanType,
    state.bnpl_conf,
  ]);

  const onClick = () => {
    if (loanType === 'pay_now') {
      setValues({ loanType: 'pay_later' });
    }

    if (loanType === 'pay_later') {
      setValues({ loanType: 'pay_now' });
    }
  };

  const shouldShow = useMemo(() => {
    if (
      loanType === 'pay_now' &&
      bnpl_conf?.bnpl?.pay_now?.switch_to_pay_later
    ) {
      return true;
    }

    if (
      loanType === 'pay_later' &&
      bnpl_conf?.bnpl?.pay_later?.switch_to_pay_now
    ) {
      return true;
    }

    return false;
  }, [loanType]);

  const text = useMemo(() => {
    if (loanType === 'pay_now') {
      return bnpl_conf?.bnpl?.pay_now?.switch_to_pay_later_label;
    }

    if (loanType === 'pay_later') {
      return bnpl_conf?.bnpl?.pay_later?.switch_to_pay_now_label;
    }
  }, [loanType]);

  return (
    <>
      {shouldShow && (
        <div
          className="row-between background-neutral padding-12-16 mt-20 radius-6 cursor"
          onClick={onClick}
        >
          <p className="sm-regular color-white">{text}</p>

          <img src={rightArrow} alt="pay all now icon" />
        </div>
      )}
    </>
  );
}

export { PayAllNow };
