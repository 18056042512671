import { useState } from 'react';

export interface Values {
  length: boolean;
  upperCase: boolean;
  lowerCase: boolean;
  char: boolean;
}

const usePasswordValidation = () => {
  const [length, setLengthError] = useState<boolean>(false);
  const [upperCase, setUpperCaseError] = useState<boolean>(false);
  const [lowerCase, setLowerCaseError] = useState<boolean>(false);
  const [char, setCharError] = useState<boolean>(false);

  const validate = (value: string) => {
    if (value.length > 7) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }
    if (/[A-Z]/.test(value)) {
      setUpperCaseError(true);
    } else {
      setUpperCaseError(false);
    }
    if (/[a-z]/.test(value)) {
      setLowerCaseError(true);
    } else {
      setLowerCaseError(false);
    }
    if (/[=!@#&$%]/.test(value)) {
      setCharError(true);
    } else {
      setCharError(false);
    }
  };

  const values = { length, upperCase, lowerCase, char };
  const validated = Object.values(values).every((item) => item);

  return { validate, values, validated };
};

export default usePasswordValidation;
