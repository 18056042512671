import { useContext, useEffect, useRef, useState } from 'react';
import { Image, HStack, Spinner } from '@chakra-ui/react';
import useApplicationStore from '../state';
import '../assets/styles/nav.scss';

import French from '../assets/french.svg';
import English from '../assets/english.svg';
import Swahili from '../assets/swahili.svg';
import Rwanda from '../assets/rwanda.svg';
import ArrowDown from '../../public/new-arrow-down.svg';
import { SupportedLocales } from '../utils/localeConfig';
import { LanguageSwitchContext } from '../utils/contexts';

interface LanguageProps {
  name: string;
  code: string;
  locale?: string;
  image: string;
  calendarLocale?: string;
  countryCode?: string;
  shortCode?: string;
}

function LanguageDropdown() {
  const [showLanguageDrop, setShowLanguageDrop] = useState(false);
  const [languageSwitchLoader, setLanguageSwitchLoader] = useState(false);

  const { setCurrentLocale } = useContext(LanguageSwitchContext);

  const [setLanguage, token, getLoanDetails, languageName] =
    useApplicationStore((state) => [
      state.setLanguage,
      state.token as string,
      state.getLoanDetails,
      state.languageName,
    ]);

  const languageArray = [
    {
      name: 'English',
      code: 'en',
      locale: 'en-US',
      image: English,
      calendarLocale: SupportedLocales.enUS,
      countryCode: 'ng',
      shortCode: 'ENG',
    },
    {
      name: 'French',
      code: 'fr',
      locale: 'fr-FR',
      image: French,
      calendarLocale: SupportedLocales.fr,
      countryCode: 'fr',
      shortCode: 'FRA',
    },
    {
      name: 'Swahili',
      code: 'sw',
      locale: 'sw-KE',
      image: Swahili,
      calendarLocale: SupportedLocales.sw,
      countryCode: 'rw',
      shortCode: 'SWA',
    },
    {
      name: 'Kinyarwanda',
      code: 'rw',
      locale: 'rw-RU',
      image: Rwanda,
      calendarLocale: SupportedLocales.rw,
      countryCode: 'rw',
      shortCode: 'KIN',
    },
  ];

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dropdown = dropdownRef.current;

    const handleClick = (e: MouseEvent) => {
      if (!dropdown?.contains(e.target as Node)) {
        setShowLanguageDrop(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleLanguageClick = (language: LanguageProps) => {
    setLanguageSwitchLoader(true);
    setLanguage(
      language.code,
      language.shortCode as string,
      language.image,
      language.countryCode as string,
      language.locale as string
    );
    getLoanDetails(token, language.locale);
    setCurrentLocale(language.calendarLocale as string);

    setTimeout(() => {
      setLanguageSwitchLoader(false);
    }, 3000);
    setShowLanguageDrop(false);
  };

  return (
    <div className="language-container" ref={dropdownRef}>
      <HStack
        display={'flex'}
        flexDir={'row'}
        justifyContent={'center'}
        h={'100%'}
        gap={'0'}
        onClick={() => setShowLanguageDrop(!showLanguageDrop)}
      >
        {languageSwitchLoader && <Spinner mr="10px" size={'xs'} />}

        <p className="language-text">{languageName}</p>
        <img src={ArrowDown} alt="arrow down" className="toggle-icon" />
      </HStack>

      {showLanguageDrop && (
        <div className="language-drop-container">
          {languageArray.map(
            (language: { name: string; code: string; image: string }) => {
              return (
                <HStack
                  display={'flex'}
                  flexDir={'row'}
                  justifyContent={'flex-start'}
                  padding={'5px 20px'}
                  key={language.name}
                >
                  <Image width={'20px'} height={'17px'} src={language.image} />
                  <p onClick={() => handleLanguageClick(language)}>
                    {language.name}
                  </p>
                </HStack>
              );
            }
          )}
        </div>
      )}
    </div>
  );
}

export { LanguageDropdown };
