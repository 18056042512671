import plusIcon from '../../assets/plus_icon.svg';
import { Spacer } from './Spacer';

type AddItemType = {
  text: string;
  onClick: () => void;
};

function AddItem({ text, onClick }: Readonly<AddItemType>) {
  return (
    <div className="bank-add-item" onClick={onClick}>
      <img src={plusIcon} alt="plus icon" />
      <Spacer width={12} />
      <p className="body-medium">{text}</p>
    </div>
  );
}

export { AddItem };
