import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  InputProps,
  Textarea,
  InputGroup,
  InputRightElement,
  Image,
  HStack,
} from '@chakra-ui/react';
import React, { FormEvent, ReactNode, useEffect, useState } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegisterReturn,
} from 'react-hook-form';
import Required from '../../assets/required.png';
import moment, { Moment } from 'moment';
import { dateFormatter } from '../../utils/formatter';
import { useTranslation } from '@lendsqr/lingua-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider } from '@mui/material';
import { IoEyeOff, IoEye } from 'react-icons/io5';

interface CustomInputProps {
  id?: string;
  prefix?: string;
  errorMessage?: ReactNode | string;
  isInvalid?: FieldError | Merge<FieldError, FieldErrorsImpl>;
  label: string;
  formHook?: UseFormRegisterReturn<string>;
  isWorkEmail?: ReactNode;
  disabled?: boolean;
  type?: string;
  inputMode?: InputProps['inputMode'];
  value?: string | number;
  defaultValue?: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  amountInput?: boolean;
  currency?: boolean;
  onInput?: (event: FormEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
}

interface DateInputProps extends Omit<CustomInputProps, 'onChange'> {
  inputProps?: any;
  viewDate?: any;
  minDate?: any;
  setValue: any;
  setError: any;
  clearErrors: any;
  initialDate: string | Moment | Date;
  disablePast?: boolean;
  disableFuture?: boolean;
}

interface CustomTextareaProps {
  id?: string;
  errorMessage?: ReactNode;
  isInvalid: FieldError | Merge<FieldError, FieldErrorsImpl> | undefined;
  label: string;
  formHook?: UseFormRegisterReturn<string>;
  disabled?: boolean;
  type?: string;
  inputMode?: InputProps['inputMode'];
  value?: string | number;
  defaultValue?: string;
  placeholder?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
}

const CustomInput = ({
  id,
  label,
  isInvalid,
  errorMessage,
  formHook,
  isWorkEmail,
  required = true,
  ...rest
}: CustomInputProps) => (
  <FormControl isInvalid={!!isInvalid} id={id}>
    <HStack gap={'0.2rem'} alignItems={'flex-start'}>
      <FormLabel
        color={'rgba(33, 37, 41, 0.7)'}
        fontSize="0.885rem"
        mb="4px"
        mx="0"
        fontWeight={'light'}
        sx={{
          '@media screen and (min-width: 768px) and (orientation: portrait)': {
            fontWeight: 'bold',
          },
          '@media screen and (min-width: 1024px)': {
            fontWeight: 'bold',
          },
        }}
      >
        {label}
      </FormLabel>
      {required ? <Image src={Required} width={'7px'} height={'7px'} /> : ''}
    </HStack>
    <Input
      h="54.6px"
      borderRadius={'15px'}
      variant={{ base: 'outline' }}
      border="1px solid #b0b8c8"
      sx={{
        '&:disabled': {
          border: '1px solid #b0b8c8',
          bg: '#ECEEF0',
          opacity: '1',
        },
      }}
      _placeholder={{
        fontSize: '0.7rem',
        color: 'hsl(0, 0%, 50%)',
        opacity: '0.5',
      }}
      {...formHook}
      {...rest}
    />
    {isWorkEmail}
    <Box pt="0.5rem" h={`${errorMessage ? '2rem' : '15px'}`}>
      <FormErrorMessage mt="0" fontSize="0.65rem">
        {errorMessage}
      </FormErrorMessage>
    </Box>
  </FormControl>
);

const ControlInput = ({
  id,
  label,
  isInvalid,
  errorMessage,
  defaultValue,
  required = true,
  ...rest
}: CustomInputProps) => (
  <FormControl px="1" isInvalid={!!isInvalid} id={id}>
    <HStack gap={'0.2rem'} alignItems={'flex-start'}>
      <FormLabel
        color={'rgba(33, 37, 41, 0.7)'}
        fontSize="0.885rem"
        mb="4px"
        fontWeight={'light'}
        mx={'0'}
        sx={{
          '@media screen and (min-width: 768px) and (orientation: portrait)': {
            fontWeight: 'bold',
          },
          '@media screen and (min-width: 1024px)': {
            fontWeight: 'bold',
          },
        }}
      >
        {label}
      </FormLabel>
      {required ? <Image src={Required} width={'7px'} height={'7px'} /> : ''}
    </HStack>
    <Input
      h="54.6px"
      borderRadius={'15px'}
      border="1px solid #b0b8c8"
      defaultValue={defaultValue ?? ''}
      isDisabled={!!defaultValue}
      variant={{ base: 'outline' }}
      sx={{
        '&:disabled': {
          border: '1px solid #b0b8c8',
          bg: '#ECEEF0',
          opacity: '1',
        },
      }}
      _placeholder={{
        fontSize: '0.7rem',
        color: 'hsl(0, 0%, 50%)',
        opacity: '0.5',
      }}
      {...rest}
    />
    <Box h={`${errorMessage ? '2rem' : '15px'}`}>
      <FormErrorMessage mt="0.5rem" fontSize="0.65rem">
        {errorMessage}
      </FormErrorMessage>
    </Box>
  </FormControl>
);

const CustomTextarea = ({
  id,
  label,
  isInvalid,
  errorMessage,
  formHook,
  rows,
  ...rest
}: CustomTextareaProps) => {
  return (
    <FormControl isInvalid={!!isInvalid} id={id}>
      <FormLabel
        color={'rgba(33, 37, 41, 0.7)'}
        fontSize="0.885rem"
        mb="4px"
        fontWeight={'light'}
        sx={{
          '@media screen and (min-width: 768px) and (orientation: portrait)': {
            color: '#213f7d',
            fontWeight: 'bold',
          },
          '@media screen and (min-width: 1024px)': {
            color: '#213f7d',
            fontWeight: 'bold',
          },
        }}
      >
        {label}
      </FormLabel>
      <Textarea
        borderRadius={'15px'}
        variant={{ base: 'outline' }}
        border="1px solid #b0b8c8"
        sx={{
          '&:disabled': {
            border: '1px solid #b0b8c8',
            bg: '#ECEEF0',
            opacity: '1',
          },
        }}
        _placeholder={{
          fontSize: '1rem',
          color: 'hsl(0, 0%, 50%)',
          opacity: '0.5',
        }}
        rows={rows}
        {...formHook}
        {...rest}
      />
      <Box pt="0.5rem" h="2rem">
        <FormErrorMessage mt="0" fontSize="0.65rem">
          {errorMessage}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
};

const PasswordInput = ({
  id,
  label,
  isInvalid,
  errorMessage,
  formHook,
  required = true,
  ...rest
}: CustomInputProps) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl px="1" isInvalid={!!isInvalid} id={id}>
      <HStack gap={'0.2rem'} alignItems={'flex-start'}>
        <FormLabel
          color={'rgba(33, 37, 41, 0.7)'}
          fontSize="0.885rem"
          mb="4px"
          mx="0"
          fontWeight={'light'}
          sx={{
            '@media screen and (min-width: 768px) and (orientation: portrait)':
              {
                fontWeight: 'bold',
              },
            '@media screen and (min-width: 1024px)': {
              fontWeight: 'bold',
            },
          }}
        >
          {label}
        </FormLabel>
        {required ? <Image src={Required} width={'7px'} height={'7px'} /> : ''}
      </HStack>
      <InputGroup>
        <Input
          h="54.6px"
          borderRadius={'15px'}
          border="1px solid #b0b8c8"
          type={`${show ? 'text' : 'password'}`}
          variant={{ base: 'outline' }}
          sx={{
            '&:disabled': {
              border: '1px solid #b0b8c8',
              bg: '#ECEEF0',
              opacity: '1',
            },
          }}
          _placeholder={{
            fontSize: '0.7rem',
            color: 'hsl(0, 0%, 50%)',
            opacity: '0.5',
          }}
          {...formHook}
          {...rest}
        />
        <InputRightElement
          width="4.5rem"
          height={'full'}
          cursor={'pointer'}
          onClick={handleClick}
        >
          {show ? <IoEye /> : <IoEyeOff />}
        </InputRightElement>
      </InputGroup>
      <Box h={`${errorMessage ? '2rem' : '15px'}`}>
        <FormErrorMessage mt="0.5rem" fontSize="0.65rem">
          {errorMessage}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
};

const DateInput = ({
  id,
  label,
  isInvalid,
  inputProps,
  viewDate,
  minDate,
  setValue,
  initialDate,
  placeholder,
  setError,
  clearErrors,
  required = true,
  disablePast = false,
  disableFuture = false,
}: DateInputProps) => {
  const muiTheme = createTheme();

  const { translate } = useTranslation();
  const [date, setDate] = useState<string | Moment | Date>(initialDate);
  const [zIndex, setZIndex] = useState(10); // Initialize zIndex state

  useEffect(() => {
    setZIndex((prevZIndex) => prevZIndex - 1);
  }, []);

  const dateChange = (date: Moment | null) => {
    setValue(id, moment(date).format('YYYY/MM/DD'));
    setDate(moment(date).format('YYYY/MM/DD'));

    if (moment(date).format('YYYY/MM/DD') === 'Invalid date') {
      setError(id, {
        type: 'manual',
        message: 'Please enter a valid date',
      });
    } else {
      const formattedDate = moment(date).format('YYYY/MM/DD');
      const maxDate = viewDate ? moment(viewDate).format('YYYY/MM/DD') : null;
      const minimumDate = minDate ? moment(minDate).format('YYYY/MM/DD') : null;

      // Check if the date is out of bounds
      if (maxDate && formattedDate > maxDate) {
        setError(id, {
          type: 'manual',
          message: `Date cannot be after ${maxDate}`,
        });
      } else if (minimumDate && formattedDate < minimumDate) {
        setError(id, {
          type: 'manual',
          message: `Date cannot be before ${minimumDate}`,
        });
      } else {
        clearErrors(id);
      }
    }
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <FormControl
        px="1"
        id={id}
        isInvalid={!!isInvalid}
        position={'relative'}
        zIndex={zIndex.toString()}
      >
        <HStack gap={'0.2rem'} alignItems={'flex-start'}>
          <FormLabel
            color={'rgba(33, 37, 41, 0.7)'}
            fontSize="0.885rem"
            mb="4px"
            mx="0"
            fontWeight={'light'}
            sx={{
              '@media screen and (min-width: 768px) and (orientation: portrait)':
                {
                  fontWeight: 'bold',
                },
              '@media screen and (min-width: 1024px)': {
                fontWeight: 'bold',
              },
            }}
          >
            {`${label} (${
              date && (date as string)?.length == 10
                ? dateFormatter(date as Date)
                : `${translate('invitation-web-app-enter-date')}`
            })`}
          </FormLabel>
          {required && <Image src={Required} width={'7px'} height={'7px'} />}
        </HStack>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            className={`date-selector ${inputProps}`}
            format="YYYY/MM/DD"
            onChange={(date) => dateChange(date)}
            views={['year', 'month', 'day']}
            defaultValue={moment(date)}
            disablePast={disablePast}
            disableFuture={disableFuture}
            maxDate={viewDate ? moment(viewDate) : undefined}
            minDate={minDate ? moment(minDate) : undefined}
            slotProps={{ textField: { placeholder: placeholder } }}
          />
        </LocalizationProvider>
      </FormControl>
    </ThemeProvider>
  );
};

export { CustomInput, ControlInput, PasswordInput, CustomTextarea, DateInput };
