import { fieldValidation } from './fieldValidation';
import * as Yup from 'yup';

export const pagesValidation = async (loanProduct: any) => {
  const fieldsValid: any = {};

  const fieldValidationPromises: any[] = [];

  for (let page = 0; page < loanProduct?.pages.length; page++) {
    const currentPage = loanProduct?.pages[page];

    for (let section = 0; section < currentPage?.sections.length; section++) {
      const currentSection = currentPage.sections[section];

      for (let field = 0; field < currentSection?.fields.length; field++) {
        const currentField = currentSection?.fields[field];
        fieldValidationPromises.push(
          fieldValidation(currentField).then((result) => {
            fieldsValid[currentField.id] = result;
          })
        );
      }
    }
  }

  await Promise.all(fieldValidationPromises);

  return Yup.object().shape(fieldsValid);
};

export const setFieldValidation = async (fields: any) => {
  const fieldsValid: any = {};

  const fieldValidationPromises: any[] = [];

  for (let field = 0; field < fields.length; field++) {
    const currentField = fields[field];

    fieldValidationPromises.push(
      fieldValidation(currentField).then((result) => {
        fieldsValid[currentField.id] = result;
      })
    );
  }

  await Promise.all(fieldValidationPromises);

  return Yup.object().shape(fieldsValid);
};
