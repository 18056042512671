type ButtonProp = {
  text: string;
  type: string;
  extraClass?: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
};

function Button({
  text,
  type,
  extraClass,
  onClick,
  disabled,
  loading,
}: Readonly<ButtonProp>) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`bnpl-btn sm-bold ${
        type === 'primary' ? 'bnpl-primary' : 'bnpl-outline'
      } ${extraClass}`}
    >
      {text}

      {loading && <span className="ml-8 loader-new"></span>}
    </button>
  );
}

export { Button };
