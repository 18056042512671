// localeConfig.ts

import { registerLocale } from 'react-datepicker';
import { enUS, fr } from 'date-fns/locale';

// Register the Swahili locale
// registerLocale('sw', swahiliLocale);

// Register locales
registerLocale('en-US', enUS);
registerLocale('fr', fr);

export const SupportedLocales = {
  enUS: 'en-US',
  fr: 'fr',
  sw: 'en-US',
  rw: 'en-US',
};
