import { useField } from 'formik';
import moment, { Moment } from 'moment';
import './new-date-element.scss';
import { useEffect, useState } from 'react';
import { dateFormatter } from '../../utils/formatter';

type DateFieldProps = {
  minDate?: string | Date | Moment;
  maxDate?: string | Date | Moment;
  label?: string;
  name: string;
  groupClassName?: string;
  initialDate?: string | Moment | Date;
  disabled?: boolean;
};

function DateField({
  minDate,
  maxDate,
  name,
  groupClassName,
  initialDate,
  label = 'Select a Date',
  disabled,
}: Readonly<DateFieldProps>) {
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);
  const [dayOpen, setDayOpen] = useState(false);
  const [monthOpen, setMonthOpen] = useState(false);
  const [yearOpen, setYearOpen] = useState(false);

  const months = moment.months(); // Get months using moment.js
  const [days, setDays] = useState<number[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const getDaysInMonth = (year: number, month: number) => {
      if (!year || !month) {
        return 30; // Return 30 if year or month is null or invalid
      }
      return moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
    };

    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
  }, [selectedYear, selectedMonth]);

  // Determine the range of years
  const defaultMinYear = 1900;
  const defaultMaxYear = moment().year();

  const minYear = minDate ? moment(minDate).year() : defaultMinYear;
  const maxYear = maxDate ? moment(maxDate).year() : defaultMaxYear;

  // Generate year options
  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, i) => maxYear - i
  ); // Get the last 101 years using moment.js

  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (initialDate !== '') {
      helpers.setValue(initialDate);
    }

    // Setting initial dates
    const { monthIndex, yearIndex, dayName } = getMonthFromDate(initialDate);
    setSelectedDay(dayName);
    setSelectedYear(yearIndex);
    setSelectedMonth(monthIndex);
  }, [initialDate]);

  function getMonthFromDate(dateString: string | Moment | Date | undefined) {
    const date = moment(dateString);
    const monthIndex = parseInt(`${date.month()}`, 10) + 1;
    const dayIndex = date.day();
    const yearIndex = date.year();
    const monthName = date.format('MMMM');
    const dayName = Number(date.format('D'));
    const yearName = date.format('YYYY');

    return { monthIndex, monthName, dayName, yearName, dayIndex, yearIndex };
  }

  const handleDayChange = (e: any) => {
    setSelectedDay(e.target.value);
    dateChange(e.target.value, selectedMonth, selectedYear);
  };

  const handleMonthChange = (e: any) => {
    setSelectedMonth(e.target.value);
    dateChange(selectedDay, e.target.value, selectedYear);
  };

  const handleYearChange = (e: any) => {
    setSelectedYear(e.target.value);
    dateChange(selectedDay, selectedMonth, e.target.value);
  };

  const dateChange = (day: any, month: any, year: any) => {
    const date = moment({
      year: parseInt(year, 10),
      month: parseInt(month, 10) - 1,
      date: parseInt(day, 10),
    });

    if (!date.isValid()) {
      helpers.setError('Please enter a valid date');
      setError('Please enter a valid date');

      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);

      return;
    }

    helpers.setValue(date.format('YYYY/MM/DD'));

    const formattedDate = date.format('YYYY/MM/DD');
    const maximumDate = maxDate ? moment(maxDate).format('YYYY/MM/DD') : null;
    const minimumDate = minDate ? moment(minDate).format('YYYY/MM/DD') : null;

    if (maximumDate && formattedDate > maximumDate) {
      helpers.setError(`Date cannot be after ${maximumDate}`);
      setError(`Date cannot be after ${maximumDate}`);
    } else if (minimumDate && formattedDate < minimumDate) {
      helpers.setError(`Date cannot be before ${minimumDate}`);
      setError(`Date cannot be before ${minimumDate}`);
    } else {
      helpers.setError('');
      setError('');
    }
  };

  return (
    <div className={`form-group ${groupClassName}`}>
      <div className="input-holder-holder">
        <label className="new-date-label">
          {`${label} (${
            field.value && field.value?.length == 10
              ? dateFormatter(field.value)
              : 'Enter date'
          })`}
        </label>

        <div id={name} className="date-selects">
          <div className="select-box" onClick={() => setDayOpen(!dayOpen)}>
            {/* <span
              className={`custom-arrow ${dayOpen ? 'date-open' : 'close'}`}
            /> */}
            <select
              id="day"
              value={selectedDay}
              onChange={handleDayChange}
              onBlur={() => setDayOpen(false)}
              disabled={disabled}
              className={`${error !== '' ? 'date-select-error' : ''}`}
            >
              <span className="custom-arrow" />
              <option value="">Day</option>
              {days.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>

          <div className="select-box" onClick={() => setMonthOpen(!monthOpen)}>
            {/* <span
              className={`custom-arrow ${monthOpen ? 'date-open' : 'close'}`}
            /> */}
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              disabled={disabled}
              onBlur={() => setMonthOpen(false)}
              className={`${error !== '' ? 'date-select-error' : ''}`}
            >
              <option value="">Month</option>
              {months.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
          </div>

          <div className="select-box" onClick={() => setYearOpen(!yearOpen)}>
            {/* <span
              className={`custom-arrow ${yearOpen ? 'date-open' : 'close'}`}
            /> */}
            <select
              value={selectedYear}
              onChange={handleYearChange}
              disabled={disabled}
              onBlur={() => setYearOpen(false)}
              className={`${error !== '' ? 'date-select-error' : ''}`}
            >
              <option value="">Year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>

        {meta.error && meta.touched && (
          <>
            {/* <Spacer height={20} /> */}
            <small
              className="error"
              style={{ height: '2rem', marginTop: '-1.1rem' }}
            >
              {meta.error}
            </small>
          </>
        )}

        {/* {(error !== '' && !meta.error) && (
                <small
                className="form-text text-danger d-block text-small"
                style={{ height: '2rem', marginTop: '-1.1rem' }}
                >
                {error}
                </small>
            )} */}
      </div>
    </div>
  );
}

export { DateField };
