import { useTranslation } from '@lendsqr/lingua-react';
import { useFormikContext } from 'formik';

type CheckBoxProps = {
  name: string;
  label: string;
  validation: any;
  options: Record<string, string>[];
  field: any;
};

const CheckboxFormField = ({
  name,
  label,
  validation,
  options,
  field,
}: CheckBoxProps) => {
  const { translate } = useTranslation();
  const { getFieldMeta, handleChange, handleBlur, getFieldProps } =
    useFormikContext<Record<string, any>>();

  const meta = getFieldMeta(name);

  const onChange = (e: any) => {
    const { value } = e.target;
    const allValues = getFieldProps(name).value;
    if (
      validation?.maximum_select &&
      allValues.length >= validation?.maximum_select &&
      !allValues.includes(value)
    )
      return;
    handleChange(e);
  };

  return (
    <div className="input-holder-holder">
      <label className="mt-2 form-text">{label}</label>{' '}
      <span className="required-icon">{validation?.required ? '*' : ''} </span>
      {options.map((option) => (
        <div key={option.id} className="form-check text-app-gray">
          <input
            type="checkbox"
            className="form-check-input mr-8"
            name={name}
            id={`${name}-${option.value}`}
            checked={(getFieldMeta(name) as any).value.includes(option.value)}
            value={option.value}
            onChange={onChange}
            onBlur={handleBlur}
          />
          <label htmlFor={`${name}-${option.value}`} className="sm-medium">
            {option.label.trim()}
          </label>
        </div>
      ))}
      {meta.touched &&
        validation &&
        (getFieldMeta(name) as any).value.length === 0 && (
          <small
            className="form-text text-danger d-block text-small mt-2 error"
            style={{ height: '2rem', marginTop: '-1.1rem' }}
          >
            {translate('lender-web-app-is-required', { name: field.name })}
          </small>
        )}
    </div>
  );
};

export { CheckboxFormField };
