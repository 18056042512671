import { useCallback, useRef, useState } from 'react';
import { ScreenState } from '../bnpl';
import { BNPLBody } from '../layout/BNPLBody';
import { Footer } from '../Footer';
import selfie from '../../../assets/selfie-svg.svg';
import WebcamCapture, { FileProps } from '../../base/Camera';
import useApplicationStore from '../../../state';
import toast from 'react-hot-toast';
import { useDisclosure } from '@chakra-ui/react';

function SelfieVerification() {
  const [setScreen, setLocation, addPhoto, bnpl_conf] = useApplicationStore(
    (state) => [
      state.setBNPLScreen,
      state.setLocation,
      state.addPhoto,
      state.bnpl_conf,
    ]
  );

  const { onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<FileProps | null>(null);

  const onBack = useCallback(() => {
    setScreen(ScreenState.ACCOUNT_INFORMATION);
  }, []);

  const submit = async () => {
    let fileBlob: Blob | undefined;
    setLoading(true);

    if (file?.method === 'selfie') {
      await fetch(file.preview)
        .then((res) => res.blob())
        .then((blob) => {
          fileBlob = blob;
        })
        .catch((e) => {
          console.error(e);
          toast.error(`There was an error uploading your photo - ${e.message}`);
        });

      if (!fileBlob) {
        return;
      }
    }

    const grantLocationPermission = async () => {
      setLocation();
      onClose();
    };

    try {
      const formData = new FormData();
      if (file?.method === 'selfie') {
        formData.append('file', fileBlob as Blob, 'user-selfie.png');
      } else {
        formData.append('file', file?.file as File);
      }

      await grantLocationPermission();
      await addPhoto(formData, 'bnpl');

      setScreen(ScreenState.ORDER_INFORMATION);

      setLoading(false);
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null) {
        const errorObj = error as { message: string };
        if (errorObj.message) {
          toast.error(errorObj.message);
        }
      }
    }
  };

  const [showWebCamModal, setShowWebCamModal] = useState(false);
  const cancelCameraRef = useRef<HTMLElement>(null);

  const handleImageClick = () => {
    setShowWebCamModal(true);
  };

  return (
    <>
      <BNPLBody
        title={
          bnpl_conf?.selfie_validation?.description ??
          ' Please take a selfie in a well lit environment, we’ll compare this with your identification.'
        }
      >
        <div className="bnlp-selfie-holder flex-center">
          <img
            src={file?.preview || selfie}
            className="pointer"
            alt="default image"
            onClick={handleImageClick}
          />
        </div>

        {showWebCamModal && (
          <WebcamCapture
            showWebCamModal={showWebCamModal}
            setShowWebCamModal={setShowWebCamModal}
            setCapturedImage={setFile}
            capturedImage={file?.preview as string}
            cancelCameraRef={cancelCameraRef}
            handleSave={() => {
              setShowWebCamModal(false);
            }}
          />
        )}
      </BNPLBody>

      <Footer loading={loading} onBack={onBack} onNext={submit} />
    </>
  );
}

export { SelfieVerification };

// Token
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTk4ODE1NiwiaXNfYWRtaW4iOmZhbHNlLCJpcCI6IjEwNS4xMTMuMTE4LjIyNCIsImVtYWlsIjoib3N1c3VAbGVuZHNxci5jb20iLCJvcmdfaWQiOjQ0MzAsImlhdCI6MTcyNDAzMTU2NCwiZXhwIjoxNzI0MDM4NzY0LCJpc3MiOiJsZW5kc3FyLmNvbSJ9.dCw4koUznAaCd4wfdWxy9aZeEPHMIwBCaBh8aHMbk_Y
