import { useCallback, useEffect, useMemo, useState } from 'react';
import { frequencyData, frequencyType, money } from '../../../utils/formatter';
import { LoanOfferingsInterface } from '../../../utils/interfaces';
import { useFormikContext } from 'formik';
import useApplicationStore from '../../../state';

type LoanOfferingType = {
  product: any;
  data: LoanOfferingsInterface;
};

function LoanOffering({ product, data }: LoanOfferingType) {
  // const props = useFormikContext<{[key: string]: string;}>();

  const { setFieldValue, values, errors, setFieldTouched } = useFormikContext<{
    [key: string]: string;
  }>();

  const [bnpl_conf] = useApplicationStore((state) => [state.bnpl_conf]);

  const [selectedId, setSelectedId] = useState(-1);

  const filteredData = useMemo(() => {
    return data?.loan_amount_options?.map((option, index) => {
      return { ...option, id: index };
    });
  }, [data]);

  const offerOnchage = useCallback(
    (offer: number) => {
      const offerObject = filteredData.find((option) => option.id === offer);

      setFieldValue('requested_amount', offerObject?.principal);

      setSelectedId(offer);
    },
    [filteredData]
  );

  useEffect(() => {
    setFieldTouched('requested_amount');
    // setFieldTouched('proposed_tenor');
    // setFieldTouched('proposed_tenor_period');
    setFieldTouched('proposed_payday');
  }, [values, errors]);

  const [loanType] = useApplicationStore((state) => [state.loanType]);

  return (
    <div className="lender-offer-holder">
      {/* <Spacer height={20} /> */}
      {filteredData?.map((option: any) => {
        const randId = Math.random();
        const isSelected = selectedId === option.id;

        return (
          <label
            className={`lender-offer-options-holder ${
              isSelected ? 'lender-offer-selected' : 'lender-offer-norm'
            }`}
            key={option.id}
            htmlFor={`${randId}`}
          >
            <div className="lender-offer-top">
              <div className="lender-offer-image-holder">
                <img src={option.photo_url} alt={option.description} />
              </div>

              <div className="lender-offer-details">
                <h4 className="sm-bold">{option?.label}</h4>
                <p className="sm-regular mt-8">{option?.description}</p>

                <div
                  style={{ width: 'fit-content' }}
                  className="lender-offer-radio"
                >
                  <input
                    id={`${randId}`}
                    type="radio"
                    onChange={() => offerOnchage(option.id)}
                    checked={isSelected}
                  />
                  <label
                    htmlFor={`${randId}`}
                    className={`${
                      !isSelected
                        ? 'radio-not-selected-outter'
                        : 'radio-selected-outter'
                    }`}
                  >
                    <div
                      className={`${
                        !isSelected
                          ? 'radio-not-selected-inner'
                          : 'radio-selected-inner'
                      }`}
                    />
                  </label>
                </div>
              </div>
            </div>
            {loanType === 'pay_later' && bnpl_conf?.interest_rate?.enabled && (
              <div className="lender-offer-bottom sm-bold">
                {money(option?.principal, 'NGN')} at {product?.interest_rate}
                {frequencyData['monthly' as keyof frequencyType]}
              </div>
            )}

            {loanType === 'pay_later' && !bnpl_conf?.interest_rate?.enabled && (
              <div className="lender-offer-bottom sm-bold">
                {money(option?.principal, 'NGN')}
              </div>
            )}

            {loanType === 'pay_now' && (
              <div className="lender-offer-bottom sm-bold">
                {money(option?.principal, 'NGN')}
              </div>
            )}
          </label>
        );
      })}
    </div>
  );
}

export { LoanOffering };
