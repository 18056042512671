import { Button } from '../base/Button';

type FooterProp = {
  onBack: () => void;
  onNext?: () => void;
  disabled?: boolean;
  loading?: boolean;
  noPrimary?: boolean;
};

function Footer({
  onBack,
  onNext,
  disabled,
  loading,
  noPrimary,
}: Readonly<FooterProp>) {
  return (
    <div className="bnpl-footer helper-padding">
      <Button
        text="Back"
        type="outline"
        extraClass={noPrimary ? 'full-width' : 'mr-8'}
        onClick={onBack}
      />

      {!noPrimary && onNext && (
        <Button
          disabled={disabled || loading}
          text="Next"
          type="primary"
          extraClass="mr-8 flex-1"
          onClick={onNext}
          loading={loading}
        />
      )}
    </div>
  );
}

export { Footer };
