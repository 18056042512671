export const FIELD_TYPES = {
  short_text: 'short_text',
  long_text: 'long_text',
  date: 'date',
  time: 'time',
  date_time: 'date_time',
  integer: 'integer',
  number: 'number',
  amount: 'amount',
  phone: 'phone',
  email: 'email',
  checkbox: 'checkbox',
  drop_down: 'dropdown',
  select: 'select',
  radio: 'radio',
  label: 'label',
  upload: 'upload',
  file: 'file',
  video: 'video',
  audio: 'audio',
  image: 'image',
  api: 'api',
  remita_inflight: 'remita_inflight',
  bank: 'bank',
  url: 'url',
  smileid: 'smileid',
  veriff: 'veriff',
};
