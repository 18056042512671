import { money } from '../../../utils/formatter';
import { InfoLayout } from '../../InfoLayout';
import { ProductInformationProp } from '../bnpl';
import infoIcon from '../../../assets/info-norm.svg';
import { Spacer } from '../../base/Spacer';
import { LoanData } from '../../../utils/interfaces';
import useApplicationStore from '../../../state';

function ProductInformation({
  title,
  content,
  summary,
  htmlTitle,
}: ProductInformationProp) {
  const [loan_data] = useApplicationStore((state) => [
    state.loan_data as LoanData,
  ]);

  return (
    <InfoLayout>
      {/* <p className="md-regular mb-16">
                Your order for <span className="md-bold">{title}</span>
            </p> */}

      {title && <p className="md-bold">{title}</p>}
      {htmlTitle && <div dangerouslySetInnerHTML={{ __html: htmlTitle }} />}

      {content?.map((info) => {
        return (
          <div className="row-between order-information-item" key={info.title}>
            <p className="sm-regular flex">
              {info.title}
              {info.info && (
                <>
                  <Spacer width={4.7} />
                  <img src={infoIcon} alt="normal info icon" />
                </>
              )}
            </p>
            <p className="sm-bold">{info.value}</p>
          </div>
        );
      })}

      {summary && (
        <>
          <div className="horizontal-line mt-16 mb-16"></div>

          <div className="row-between order-information-item">
            <p className="sm-bold"> {summary?.title} </p>
            <p className="sm-bold">
              {money(summary?.value, loan_data?.currency)}
            </p>
          </div>
        </>
      )}
    </InfoLayout>
  );
}

export { ProductInformation };
