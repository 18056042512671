import { translate as outsideTranslate } from '@lendsqr/lingua-react';
import * as Yup from 'yup';
import { FIELD_TYPES } from './fieldTypes';

const phoneNumberLength = 11;

const handleDefaultTypes = async (field: any, defaultTypes: any) => {
  const valueMustBeText = (await outsideTranslate(
    'lender-web-app-valid-text'
  )) as string;
  const valueMustBeDate = (await outsideTranslate(
    'lender-web-app-value-must-date'
  )) as string;
  const enterValidEmail = (await outsideTranslate(
    'lender-web-app-valid-email'
  )) as string;
  const valueMustBePhone = (await outsideTranslate(
    'lender-web-app-value-must-phone'
  )) as string;
  const enterValidPhone = (await outsideTranslate(
    'lender-web-app-valid-number-format'
  )) as string;
  const valueMustBeInt = (await outsideTranslate(
    'lender-web-app-valid-integer'
  )) as string;
  const valueOnlyInt = (await outsideTranslate(
    'lender-web-app-only-positive-integers'
  )) as string;
  const valueOnlyString = (await outsideTranslate(
    'lender-web-app-can-only-string'
  )) as string;
  const valueOnlyNumber = (await outsideTranslate(
    'lender-web-app-only-number'
  )) as string;
  const selectOption = (await outsideTranslate(
    'lender-web-app-select-option'
  )) as string;
  const selectItem = (await outsideTranslate(
    'lender-web-app-select-item'
  )) as string;

  let validation;

  switch (field.type) {
    case defaultTypes.short_text:
    case defaultTypes.api:
      validation = Yup.string()
        .typeError(valueMustBeText)
        .matches(
          field.validation.rule,
          await outsideTranslate('lender-web-app-enter-valid-field', {
            name: field.name,
          })
        );
      break;
    case defaultTypes.long_text:
      validation = Yup.string().typeError(valueMustBeText);
      break;
    case defaultTypes.bank:
      validation = Yup.string();
      break;
    case defaultTypes.date:
      validation = Yup.date().typeError(valueMustBeDate);
      break;
    case defaultTypes.date_time:
      validation = Yup.date().typeError(valueMustBeDate);
      break;
    case defaultTypes.email:
      validation = Yup.string()
        .email(enterValidEmail)
        .typeError(enterValidEmail);
      break;
    case defaultTypes.phone:
      validation = Yup.string()
        .matches(/^0[789]\d{9}$/, enterValidPhone)
        .typeError(valueMustBePhone)
        .min(
          phoneNumberLength,
          await outsideTranslate('lender-web-app-must-be-digits', {
            phone_number_length: phoneNumberLength.toString(),
          })
        )
        .max(
          phoneNumberLength,
          await outsideTranslate('lender-web-app-must-be-digits', {
            phone_number_length: phoneNumberLength.toString(),
          })
        );
      break;
    case defaultTypes.integer:
      validation = Yup.number().integer(valueMustBeInt).typeError(valueOnlyInt);
      break;
    case defaultTypes.label:
      validation = Yup.string().typeError(valueOnlyString);
      break;
    case defaultTypes.number:
    case defaultTypes.amount:
      if (
        field?.validation?.minimum_length &&
        field?.validation?.maximum_length
      ) {
        validation = Yup.string();
      } else {
        validation = Yup.number().integer().typeError(valueOnlyNumber);
      }

      break;
    case defaultTypes.checkbox:
      validation = Yup.array();

      if (
        field.validation?.minimum_select &&
        field.validation?.maximum_select
      ) {
        validation = Yup.array()
          .min(
            field.validation.minimum_select,
            await outsideTranslate('lender-web-app-select-min-max', {
              minimum_select: field.validation.minimum_select,
              maximum_select: field.validation.maximum_select,
            })
          )
          .max(
            field.validation.maximum_select,
            await outsideTranslate('lender-web-app-select-min-max', {
              minimum_select: field.validation.minimum_select,
              maximum_select: field.validation.maximum_select,
            })
          );
      }

      if (field.validation?.maximum_select) {
        validation = Yup.array().max(
          field.validation.maximum_select,
          await outsideTranslate('lender-web-app-most-select', {
            maximum_select: field.validation.maximum_select,
          })
        );
      }

      if (field.validation?.minimum_select) {
        validation = Yup.array().min(
          field.validation.minimum_select,
          await outsideTranslate('lender-web-app-least-select', {
            minimum_select: field.validation.minimum_select,
          })
        );
      }

      break;
    case defaultTypes.radio:
      validation = Yup.string().oneOf(
        [...(field.options?.map((option: any) => option?.value) || '')],
        selectOption
      );
      break;
    case defaultTypes.drop_down:
    case defaultTypes.select:
      validation = Yup.string()
        .oneOf([...(field.options?.map((option: any) => option?.value) || '')])
        .label(selectItem);
      break;
    case defaultTypes.smileid:
    case defaultTypes.veriff:
    case defaultTypes.image:
    case defaultTypes.file:
    case defaultTypes.upload:
    case defaultTypes.audio:
    case defaultTypes.video:
    case defaultTypes.time:
      validation = Yup.mixed();
      break;
    default:
      validation = null;
      break;
  }
  return validation;
};

const isExemptFields = [
  FIELD_TYPES.veriff,
  FIELD_TYPES.smileid,
  FIELD_TYPES.image,
  FIELD_TYPES.file,
  FIELD_TYPES.upload,
  FIELD_TYPES.audio,
  FIELD_TYPES.video,
  FIELD_TYPES.date,
  FIELD_TYPES.time,
  FIELD_TYPES.url,
  FIELD_TYPES.remita_inflight,
];

const isNumberFields = [
  FIELD_TYPES.integer,
  FIELD_TYPES.number,
  FIELD_TYPES.amount,
];

export const fieldValidation = async (
  field: any,
  defaultTypes = FIELD_TYPES
) => {
  let validation;

  validation = await handleDefaultTypes(field, defaultTypes);

  if (field.validation?.required && field.validation?.required === true) {
    const translated = await outsideTranslate('lender-web-app-is-required', {
      name: field.name,
    });
    validation = validation?.required(translated);
  }

  if (
    field.validation &&
    +field.validation.maximum &&
    isNumberFields.includes(field.type)
  ) {
    validation = (validation as any)?.min(
      +field.validation.minimum,
      await outsideTranslate('lender-web-app-field-range', {
        name: field.name,
        minimum: field.validation.minimum ?? 0,
        maximum: field.validation.maximum ?? 0,
      })
    );

    validation = validation.max(
      +field.validation.maximum,
      await outsideTranslate('lender-web-app-field-range', {
        name: field.name,
        minimum: field.validation.minimum ?? 0,
        maximum: field.validation.maximum ?? 0,
      })
    );
    return validation;
  }

  if (
    field.validation &&
    +field.validation.maximum_length &&
    !isExemptFields.includes(field.type)
  ) {
    validation = (validation as any).max(
      +field.validation.maximum_length,
      await outsideTranslate('lender-web-app-field-max-length', {
        name: field.name,
        minimum_length: field.validation?.maximum_length,
      })
    );

    validation = validation.min(
      +field.validation.minimum_length,
      await outsideTranslate('lender-web-app-field-min-length', {
        name: field.name,
        maximum_length: field.validation.minimum_length,
      })
    );
  }

  if (field.validation?.disallowed) {
    const disallowedArray = field.validation.disallowed.split(',');

    for (const i of disallowedArray) {
      const strRegExPattern = new RegExp(`[^${i}]`);

      validation = validation.matches(
        strRegExPattern,
        `${field.name} is not allowed`
      );
    }
  }

  return validation;
};
