import { AddItem } from '../../../base/AddItem';
import { Spacer } from '../../../base/Spacer';

type EmptyCardsType = {
  title: string;
  description: string;
  addClick: () => void;
};

function EmptyCards({ title, description, addClick }: EmptyCardsType) {
  return (
    <>
      <div className="empty-card">
        <p className="body-bold">{title}</p>
        <Spacer height={6} />
        <p className="body-regular">{description}</p>
      </div>

      <Spacer height={16} />

      <AddItem text="Add a new card" onClick={addClick} />
    </>
  );
}

export { EmptyCards };
