import { VStack, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import removeHover from '../utils/removeHover';
import { useTranslation } from '@lendsqr/lingua-react';

const Error404 = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  return (
    <VStack maxW={{ md: '480px' }} w="full" mx="auto" py="1rem" px="1rem">
      <VStack spacing="3rem" justify="center" flex="1">
        <Text textAlign="center" color="black" fontSize="1rem">
          {translate('invitation-web-app-home-link-error')}
        </Text>
      </VStack>
      <Button
        onClick={() => navigate('/')}
        {...removeHover}
        color="white"
        bg="#24C6A1"
        width="full"
      >
        {translate('invitation-web-app-go-home')}
      </Button>
    </VStack>
  );
};

export default Error404;
