import { ProductInformation } from './ProductInformation';

import { PayAllNow } from './PayAllNow';
import useApplicationStore from '../../../state';
import { useMemo } from 'react';
import {
  frequencyData,
  getLoanAmountFromSchedule,
  money,
} from '../../../utils/formatter';
import { LoanData } from '../../../utils/interfaces';

function Information() {
  const [bnpl_values, loan_data, loanType, bnpl_conf, schedules] =
    useApplicationStore((state) => [
      state.bnpl_values,
      state.loan_data as LoanData,
      state.loanType,
      state.bnpl_conf,
      state.schedules,
    ]);

  const data = useMemo(() => {
    const infoData = [
      {
        title: 'Amount',
        value: money(bnpl_values?.requested_amount, loan_data?.currency),
      },
    ];

    if (loanType === 'pay_later') {
      if (bnpl_conf?.interest_rate?.enabled) {
        infoData.push({
          title: bnpl_conf?.interest_rate?.label ?? 'Interest',
          value: `${loan_data.product.interest_rate}${
            (frequencyData as any)[loan_data.product?.interest_period ?? '']
          }`,
        });
      }

      const validPeriod = `${bnpl_values?.proposed_tenor} ${
        bnpl_values?.proposed_tenor === 1
          ? bnpl_values?.proposed_tenor_period.replace('s', '')
          : bnpl_values?.proposed_tenor_period
      }`;

      const coverPeriod =
        !bnpl_values?.proposed_tenor || !bnpl_values?.proposed_tenor_period
          ? '-'
          : validPeriod;

      infoData.push({
        title: 'Tenor',
        value: ` ${coverPeriod}`,
      });
    }

    return infoData;
  }, [bnpl_values, loanType]);

  return (
    <>
      <ProductInformation
        content={data}
        summary={{
          title: 'Total amount payable',
          value:
            loanType === 'pay_later'
              ? money(getLoanAmountFromSchedule(schedules), loan_data?.currency)
              : money(bnpl_values.requested_amount, loan_data?.currency),
        }}
        htmlTitle={`<p class="md-regular mb-16">
                                Your order for <span class="md-bold">${
                                  bnpl_conf?.order_information?.type ?? ''
                                }</span>
                            </p>`}
      />

      <PayAllNow />
    </>
  );
}

export { Information };
