type ItemProp = {
  index: number;
  name: string;
  selected: boolean;
  last: boolean;
};

function SideItem({ index, name, selected, last }: Readonly<ItemProp>) {
  return (
    <div
      className={`helper-side-item ${selected && 'selected-helper-side-item'}`}
    >
      {selected && <div className="item-background"></div>}

      <div className="side-item-number-holder">
        {!selected && (
          <div
            className={`side-vertical-line ${last && 'last-line'}
                    ${index === 1 && 'first-line'}  `}
          ></div>
        )}

        <p
          className={`side-item-number ${
            selected ? 'sm-bold' : 'sm-regular'
          } color-text-disabled`}
        >
          {index}
        </p>
      </div>

      <p
        className={`side-item-name color-text-disabled  ${
          selected ? 'sm-bold' : 'sm-regular'
        } `}
      >
        {name}
      </p>
    </div>
  );
}

export { SideItem };
