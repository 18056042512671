import { useMemo } from 'react';
import { getValidTenorPeriodsForProduct } from '../../utils/formatter';
import { SelectField } from './SelectField';
import { Textfield } from './Textfield';
import useApplicationStore from '../../state';
import { LoanData } from '../../utils/interfaces';
import { Spacer } from './Spacer';

function Tenor() {
  const [loan_data] = useApplicationStore((state) => [
    state.loan_data as LoanData,
  ]);

  const validTenorPeriods = getValidTenorPeriodsForProduct(loan_data);

  const tenor_periods = useMemo(() => {
    return validTenorPeriods.map((period) => {
      return { key: period, value: period };
    });
  }, [validTenorPeriods]);

  return (
    <div className="tenor-period-holder row-between">
      <Textfield label="For how long?" name="proposed_tenor" type="number" />

      <Spacer width={16} />

      <SelectField
        name="proposed_tenor_period"
        label="Period"
        options={tenor_periods}
        keyField="value"
      />
    </div>
  );
}

export { Tenor };
