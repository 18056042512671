import { FormLayout } from '../layout/FormLayout';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { ScreenState } from '../bnpl';
import { SelectField } from '../../base/SelectField';
import { Textfield } from '../../base/Textfield';
import useApplicationStore from '../../../state';
import { Spacer } from '../../base/Spacer';
import { useTranslation } from '@lendsqr/lingua-react';

const initialValues = {};

function PersonalInformation() {
  const { translate } = useTranslation();

  const [getOptionsByKey, setBNPLValues, bnpl_conf, next, back] =
    useApplicationStore((state) => [
      state.getOptionsByKey,
      state.setBNPLValues,
      state.bnpl_conf,
      state.next,
      state.back,
    ]);

  const onBack = useCallback(() => {
    // setScreen(ScreenState.ORDER_INFORMATION);
    back();
  }, []);

  const onSubmit = useCallback((values: any) => {
    setBNPLValues(values);

    next(ScreenState.PERSONAL_INFORMATION, ScreenState.EDUCATIONAL_INFORMATION);
  }, []);

  const addressMessage = translate('lender-web-app-provide-valid-address');

  const validation = useMemo(() => {
    return Yup.object().shape({
      gender: Yup.string().required(
        translate('lender-web-app-please-select-gender')
      ),
      marital_status: Yup.string().required(
        translate('lender-web-app-select-marital-status')
      ),
      no_of_dependent: Yup.string().required(
        translate('lender-web-app-please-select-dependents')
      ),
      type_of_residence: Yup.string().required(
        translate('lender-web-app-select-type-residence')
      ),
      address: Yup.string()
        .min(5, addressMessage)
        .max(255, addressMessage)
        .required(addressMessage),
    });
  }, [translate]);

  return (
    <FormLayout
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
      onBack={onBack}
      title={
        bnpl_conf?.personal_information?.description ??
        "We'll use this information to verify your identity."
      }
    >
      <Spacer height={20} />

      {validation && (
        <>
          <SelectField
            name="gender"
            label="Gender"
            keyField="value"
            options={getOptionsByKey('gender')}
            groupClassName=""
          />

          <Spacer height={32} />

          <SelectField
            name="marital_status"
            label="Marital Status"
            keyField="value"
            options={getOptionsByKey('marital_status')}
            groupClassName=""
          />

          <Spacer height={32} />

          <Textfield
            type="number"
            label="Number of Children"
            name="no_of_dependent"
          />

          <Spacer height={32} />

          <SelectField
            name="type_of_residence"
            label="Type of Residence"
            keyField="value"
            options={getOptionsByKey('type_of_residence')}
            groupClassName=""
          />

          <Spacer height={32} />

          <Textfield type="text" label="Address" name="address" />
        </>
      )}
    </FormLayout>
  );
}

export { PersonalInformation };
