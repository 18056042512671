import { useTranslation } from '@lendsqr/lingua-react';
import { VStack } from '@chakra-ui/react';

const NotFound = () => {
  const { translate } = useTranslation();

  return (
    <VStack
      maxW={{ md: '480px' }}
      w="full"
      mx="auto"
      py="1rem"
      px="1rem"
      align="center"
      justify="center"
    >
      <p>{translate('invitation-web-app-not-found')}</p>
    </VStack>
  );
};

export default NotFound;
