import { useCallback, useEffect, useMemo } from 'react';
import { Footer } from '../Footer';
import { BNPLBody } from '../layout/BNPLBody';
import { ScreenState } from '../bnpl';
import { ProductInformation } from '../order-information/ProductInformation';
import { Spacer } from '../../base/Spacer';
import { PayAllNow } from '../order-information/PayAllNow';
import useApplicationStore from '../../../state';
import {
  frequencyData,
  getLoanAmountFromSchedule,
  money,
} from '../../../utils/formatter';
import { LoanData } from '../../../utils/interfaces';

function Payments() {
  const [
    bnpl_values,
    loan_data,
    getOffer,
    loading,
    loanType,
    schedules,
    bnpl_conf,
    wallet,
    getWallet,
    equityContribution,
    next,
    back,
  ] = useApplicationStore((state) => [
    state.bnpl_values,
    state.loan_data as LoanData,
    state.getOffer,
    state.loading,
    state.loanType,
    state.schedules,
    state.bnpl_conf,
    state.wallet,
    state.getWallet,
    state.equityContribution,
    state.next,
    state.back,
  ]);

  const onBack = useCallback(() => {
    back();
  }, []);

  const onSubmit = useCallback(async () => {
    if (loanType === 'pay_now') {
      next(ScreenState.PAYMENT, ScreenState.PAYMENT_METHODS);
      return;
    }

    try {
      await getOffer();

      next(ScreenState.PAYMENT, ScreenState.PAYMENT_METHODS);
    } catch (error) {
      console.log('error');
    }
  }, [loanType, loan_data]);

  useEffect(() => {
    getWallet();
  }, []);

  const data = useMemo(() => {
    const infoData = [
      {
        title: 'Amount',
        value: money(bnpl_values?.requested_amount, loan_data?.currency),
      },
    ];

    if (loanType === 'pay_later') {
      if (bnpl_conf?.interest_rate?.enabled) {
        infoData.push({
          title: bnpl_conf?.interest_rate?.label ?? 'Interest',
          value: `${loan_data.product.interest_rate}${
            (frequencyData as any)[loan_data.product?.interest_period ?? '']
          }`,
        });
      }

      const validPeriod = `${bnpl_values?.proposed_tenor} ${
        bnpl_values?.proposed_tenor === 1
          ? bnpl_values?.proposed_tenor_period.replace('s', '')
          : bnpl_values?.proposed_tenor_period
      }`;

      const coverPeriod =
        !bnpl_values?.proposed_tenor || !bnpl_values?.proposed_tenor_period
          ? '-'
          : validPeriod;

      infoData.push({
        title: 'Tenor',
        value: ` ${coverPeriod}`,
      });
    }

    return infoData;
  }, [bnpl_values, loanType]);

  return (
    <>
      <BNPLBody
        title={
          bnpl_conf?.payments_processing?.description ??
          (loanType === 'pay_later'
            ? 'Select your preferred payment option to pay the equity contribution'
            : 'Select your preferred payment option')
        }
      >
        <Spacer height={20} />

        <ProductInformation
          content={data}
          summary={{
            title: 'Total amount payable',
            value:
              loanType === 'pay_later'
                ? money(
                    getLoanAmountFromSchedule(schedules),
                    loan_data?.currency
                  )
                : money(bnpl_values?.requested_amount, loan_data?.currency),
          }}
          htmlTitle={`<p class="md-regular mb-16">
                                    Your order for <span class="md-bold">Vehicle insurance - Car</span>
                                </p>`}
        />

        <PayAllNow />

        <Spacer height={20} />

        {wallet?.virtual_accounts &&
          equityContribution > 0 &&
          loanType !== 'pay_now' && (
            <>
              <p className="sm-bold">
                To pay the initial amount via transfer, please send{' '}
                {money(equityContribution, loan_data?.currency)} to the account
                details below . If approved, the amount will be debited. If
                insufficient funds are available, your debit card will be
                charged.
              </p>
              <Spacer height={16} />
              <ul style={{ marginLeft: '20px' }}>
                <li className="body-regular">
                  {wallet?.virtual_accounts[0]?.account_number}
                </li>
                <li className="body-regular">
                  {wallet?.virtual_accounts[0]?.bank_name}
                </li>
              </ul>
            </>
          )}

        {/* <p className='sm-regular'>
          Your wallet would be debited with {amount} for initial payment
        </p> */}

        {/* <ShowCards content={cardData} /> */}
      </BNPLBody>

      <Footer loading={loading} onBack={onBack} onNext={onSubmit} />
    </>
  );
}

export { Payments };
