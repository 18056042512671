import { formatAmount } from '../../utils/formatter';
import { Spacer } from './Spacer';

type BlueAmountSummaryCardProp = {
  amount: number;
  currency: string;
  tenorText: string;
  summary: string;
};

function BlueAmountSummaryCard({
  amount,
  currency,
  tenorText,
  summary,
}: Readonly<BlueAmountSummaryCardProp>) {
  return (
    <div className="padding-20-16 background-neutral radius-4">
      <div className="summary-top">
        <p>
          <span className="heading-h4 mr-8">{currency.toUpperCase()}</span>

          <span className="heading-h2">{formatAmount(amount)}</span>
        </p>

        <Spacer height={8} />

        <p className="sm-regular color-white-80">{tenorText}</p>

        <Spacer height={12} />
      </div>

      <Spacer height={12} />

      <p className="sm-bold color-white">{summary}</p>
    </div>
  );
}

export { BlueAmountSummaryCard };
