function Spacer({
  height,
  width,
  display = 'block',
}: Readonly<{
  height?: number;
  width?: number;
  display?: string;
}>) {
  return (
    <span
      style={{ height: `${height}px`, width: `${width}px`, display }}
    ></span>
  );
}

export { Spacer };
