import { capitalize } from 'lodash';
import { money, repaymentDates } from '../../../../utils/formatter';
import { Spacer } from '../../../base/Spacer';

function SingleMandate({
  mandate,
  isSelected,
  setSelected,
}: {
  mandate: any;
  isSelected?: boolean;
  setSelected: (mandate: any) => void;
}) {
  const randId = Math.random();

  return (
    <label
      className={`box-transfer-item
             ${isSelected && 'selected-box-transfer-item'}`}
      htmlFor={`${randId}`}
    >
      {isSelected && <div className="item-background"></div>}

      <div className="row-between full-width box-transfer-top">
        <div
          style={{ width: 'fit-content' }}
          className="card-transfer-radio box-transfer-radio"
        >
          <input
            id={`${randId}`}
            type="radio"
            onChange={() => setSelected(mandate)}
            checked={isSelected}
          />

          <label
            htmlFor={`${randId}`}
            className={`${
              !isSelected
                ? 'radio-not-selected-outter'
                : 'radio-selected-outter'
            }`}
          >
            <div
              className={`${
                !isSelected
                  ? 'radio-not-selected-inner'
                  : 'radio-selected-inner'
              }`}
            />
          </label>
        </div>
        <div className="flex">
          {/* <Spacer width={12} /> */}

          <p className="sm-bold">{capitalize(mandate.status)}</p>
        </div>

        <p className="sm-regular">{repaymentDates(mandate.end_date)}</p>
      </div>

      <Spacer height={16} />

      <div className="box-transfer-bottom">
        <div className="row-between full-width">
          <p className="body-regular">{mandate.mandate_id}</p>
          <p className="body-bold">
            {money(mandate?.amount, mandate?.currency)}
          </p>
        </div>

        <Spacer height={12} />

        <div className="flex">
          <div className="mandate-image">
            <img src={mandate?.details.url} />
          </div>

          <Spacer width={12} />

          <div className="flex-column">
            <p className="sm-regular">{mandate?.details?.code_description}</p>
            <p className="body-regular">
              {mandate?.payer_account} |{' '}
              <span className="text-primary">{mandate?.payer_name}</span>
            </p>
          </div>
        </div>
      </div>
    </label>
  );
}

export { SingleMandate };
