import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { Toaster } from 'react-hot-toast';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: '#ffffff',
      },
      '*': {
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
      },
      '::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  },
  components: {
    Button: {
      variants: {
        transparent: {
          bg: 'transparent',
        },
        green: {
          bg: '#39cdcc',
          color: '#fff',
          _hover: {
            _disabled: {
              bg: '#2ba8a8',
            },
            bg: '#2ba8a8',
          },
        },
        isFullWidth: true,
      },
    },
    Input: {
      variants: {
        bottomBorder: {
          field: {
            borderBottomColor: 'gray.800',
          },
        },
      },
    },
  },
});

Sentry.init({
  dsn:
    window.location.hostname === 'localhost'
      ? null
      : import.meta.env.VITE_REACT_APP_SENTRY_KEY,
  environment: import.meta.env.NODE_ENV || 'development',
  debug: import.meta.env.NODE_ENV === 'development',
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        },
        success: {
          duration: 3000,
        },
        // theme: {
        //   primary: 'green',
        //   secondary: 'black',
        // },
      }}
    />
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>
);
