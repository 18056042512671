import { Form, Formik } from 'formik';
import { BNPLBody } from './BNPLBody';
import { Footer } from '../Footer';

/* eslint-disable react/prop-types */
type FormLayoutProps = {
  initialValues: any;
  onSubmit: (values: any) => void;
  validationSchema?: any;
  onBack?: () => void;
  onNext?: () => void;
  children: React.ReactNode;
  title: string;
  disabled?: boolean;
  loading?: boolean;
};

function FormLayout({
  initialValues,
  onSubmit,
  validationSchema,
  onBack = () => {},
  onNext = () => {},
  children,
  title,
  disabled,
  loading,
}: Readonly<FormLayoutProps>) {
  // // const stepState = useStepValidation(
  // //     'requested_amount',
  // //     'proposed_tenor',
  // //     'proposed_tenor_period',
  // //     'proposed_payday',
  // //     'purpose'
  // //     );

  // const stepState = useStepValidation(pickArray ?? [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema ?? null}
      className="new-loan"
    >
      {(props) => (
        <Form className="form-layout">
          <BNPLBody title={title}>{children}</BNPLBody>

          <Footer
            loading={loading}
            disabled={!props.isValid || disabled === true}
            onBack={onBack}
            onNext={onNext}
          />
        </Form>
      )}
    </Formik>
  );
}

export { FormLayout };
