import React, { Dispatch, ReactNode, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

interface CardModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  setShowPaystackModal: Dispatch<React.SetStateAction<boolean>>;
  setShowModal: Dispatch<React.SetStateAction<boolean>>;
}

const CardModal = ({
  isOpen,
  onClose,
  setShowPaystackModal,
  setShowModal,
  children,
}: CardModalProps) => {
  useEffect(() => {
    setShowPaystackModal(true);
  }, []);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        width={'90%'}
        maxW={'600px'}
        height={'550px'}
        maxH={'700px'}
        sx={{
          '@media screen and (min-width: 768px) and (orientation: portrait)': {
            height: '700px',
          },
          '@media screen and (min-width: 1024px)': {
            height: '700px',
          },
        }}
      >
        <ModalHeader>Add a new card</ModalHeader>
        <ModalCloseButton onClick={() => setShowModal(false)} />
        <ModalBody
          padding={'0'}
          sx={{
            '@media screen and (min-width: 768px) and (orientation: portrait)':
              {
                padding: '20px',
              },
            '@media screen and (min-width: 1024px)': {
              padding: '20px',
            },
          }}
        >
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CardModal;
